import moment from "moment";
import { isAndroid, isIOS } from "react-device-detect";

export const getBatteryColor = (chargeLevel, isVlektra, isFleet) => {
  if (isVlektra) {
    if (chargeLevel > 10 && chargeLevel <= 100) {
      if (isFleet) {
        return "#1CADA1";
      }
      return "#F0C900";
    } else {
      return "red";
    }
  } else {
    if (chargeLevel >= 20 && chargeLevel <= 50) {
      return "yellow";
    } else if (chargeLevel >= 51 && chargeLevel <= 100) {
      return "#1CADA1";
    } else if (chargeLevel >= 0 && chargeLevel <= 19) {
      return "red";
    } else {
      return "blue";
    }
  }
};

/// handler used in mobile events
export const sendEventToAndroid = (functionType) => {
  return isAndroid && window.Android && functionType in window.Android;
};

export const sendEventToiOS = (functionType) => {
  return isIOS && functionType in window.webkit.messageHandlers;
};

export const isVlektra = (value) =>
  value === "vlektra-retro" ||
  value === "vlektra-bolt" ||
  value === "vlektra-1969" ||
  value === "vlektra-velocity"
    ? true
    : false;

export const vlektraBackgroundColor = "#FFED92";

export const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "PKR",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const isDev = () =>
  !process.env.NODE_ENV || process.env.NODE_ENV === "development";

export const getCurrentTimestamp = () => moment().format("x");
export const getCurrentDate = () => moment().format("yyyy-MM-DD");
