import Pusher from "pusher-js";

export const connectPusher = async (domain: any): Promise<any> => {
    try {
        const pusher = new Pusher(process.env.REACT_APP_PUSHER_ENV, {
            cluster: "ap2",
            authEndpoint: `${process.env.REACT_APP_SERVER}/api/v1/cvp/pusher/auth`,
            auth: {
                headers: {
                    "Domain": domain
                }
            }
        });
        return pusher;
    } catch (error) {
        console.log("error:",error);
        return {};
    }

};