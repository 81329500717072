import { IDataFromMobile } from "../../Entities/Interfaces/IDataFromMobile";

export enum PusherType {
    SetData = "SET_DATA",
}

export const pusherReducer = (
    state: any,
    action: { type: any, payload: IDataFromMobile }
) => {
    const { type, payload } = action;

    switch (type) {
        case PusherType.SetData:
            console.log("payload:", payload);
            return {
                ...state,
                pusher: { ...state.pusher, ...payload.pusher },
                address: payload.address ? payload.address : state.address
            };
        default:
            return state;
    }
};
