// import React, { useEffect } from "react";
import { isVlektra, sendEventToAndroid, sendEventToiOS } from "../helper";
import { TotalDistanceGraph } from "../components";
import { useEffect } from "react";

function TotalDistance() {
  const queryParams = new URLSearchParams(window.location.search);
  const vtype = queryParams.get("vtype");
  const isFleet = queryParams.get("fleet") === "true" ? true : false;
  const cid = queryParams.get("cid");

  useEffect(() => {
    if (sendEventToAndroid("eventTotalDistanceScreen")) {
      window.Android.eventTotalDistanceScreen(
        String(
          `https://${window.location.host}/TotalDistance?vtype=${vtype}&cid=${cid}`
        ),
        String("Total Distance")
      );
    } else if (sendEventToiOS("eventTotalDistanceScreen")) {
      window.webkit.messageHandlers.eventTotalDistanceScreen.postMessage(
        String(
          `https://${window.location.host}/TotalDistance?vtype=${vtype}&cid=${cid}`
        ),
        String("Total Distance")
      );
    }
  }, []);

  return (
    <div className="container_wrapper container_wrapper_battery">
      {isVlektra(vtype) ||
      vtype === "nova_mobility-electric_3_wheeler" ||
      vtype === "nova_mobility-ecodost_electric_2_wheeler" ? (
        <TotalDistanceGraph />
      ) : (
        ""
      )}
    </div>
  );
}
export default TotalDistance;
