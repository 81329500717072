// import React, { useEffect } from "react";
import {
  getBatteryColor,
  isVlektra,
  sendEventToAndroid,
  sendEventToiOS,
  vlektraBackgroundColor,
} from "../helper";
// import { usePusher } from "../helper/Pusher/usePusher";
import { Icons } from "../assets/icons";
import { BatteryGraph } from "../components";
import { useEffect } from "react";
import { useSelector } from "react-redux";
function Vehicle() {
  const queryParams = new URLSearchParams(window.location.search);
  const vtype = queryParams.get("vtype");
  const isFleet = queryParams.get("fleet") === "true" ? true : false;
  const cid = queryParams.get("cid");
  console.log("Btterh here ", isFleet);
  const state = useSelector((state) => state.pusherSlice);
  const { pusher: data } = state;

  // const pusher = queryParams.get("pusher");

  let bg = {
    backgroundColor: isVlektra(vtype)
      ? isFleet
        ? ""
        : vlektraBackgroundColor
      : "#ddf3f1",
  };

  useEffect(() => {
    if (sendEventToAndroid("eventBatteryManagementScreen")) {
      window.Android.eventBatteryManagementScreen(
        String(
          `https://${window.location.host}/BatteryManagement?vtype=${vtype}&cid=${cid}`
        ),
        String("Battery Management")
      );
    } else if (sendEventToiOS("eventBatteryManagementScreen")) {
      window.webkit.messageHandlers.eventBatteryManagementScreen.postMessage(
        String(
          `https://${window.location.host}/BatteryManagement?vtype=${vtype}&cid=${cid}`
        ),
        String("Battery Management")
      );
    }
  }, []);

  // const { data } = usePusher();

  // const { data, setData } = usePusher(
  //   pusher || localStorage.getItem("pusher") ? JSON.parse(pusher) || JSON.parse(localStorage.getItem("pusher")) :
  //     {
  //       remaining_distance: "0",
  //       soc: 0,
  //       batteries: {},
  //     },
  //   props.pusher
  // );
  // useEffect(() =>
  // {
  //   setData(pusher || localStorage.getItem("pusher") ? JSON.parse(pusher) || JSON.parse(localStorage.getItem("pusher"))
  //     : null)
  // }, [])

  function Box({ title, value, small }) {
    return (
      <div
        className={`box${small ? " small" : ""}`}
        style={{
          ...bg,
        }}
      >
        <div className="box-title">{title}</div>
        <div className="box-value">{value}</div>
      </div>
    );
  }

  const BatteryUI = ({ index, charge, length, type, status }) => {
    return (
      <div key={"battery" + index}>
        {Number(length) > 1 && (
          <div className="slot-number">Slot#{index + 1}</div>
        )}
        <div className="charge-level">
          <span>Charge Level</span>
          {isVlektra(vtype)}
          {type === "single" && !isFleet && isVlektra(vtype) ? (
            <span className="status">
              Status:
              {[true, false].includes(status) && status ? (
                <span
                  className={
                    isVlektra(vtype)
                      ? isFleet
                        ? "text-green"
                        : "text-yellow"
                      : "text-green"
                  }
                >
                  Connected
                </span>
              ) : (
                <span className="text"> Disconnected </span>
              )}
            </span>
          ) : (
            ""
          )}
        </div>
        {isVlektra(vtype) ? (
          <div className="charge-level-value"></div>
        ) : (
          <div className="charge-level-value">{charge ?? 0}%</div>
        )}
        <center>
          <div className="battery-container">
            <div
              className="battery-filled"
              style={{
                backgroundColor: getBatteryColor(
                  charge,
                  isVlektra(vtype),
                  isFleet
                ),
                width: `calc(${charge}% - ${charge > 10 ? "15px" : "0px"})`,
              }}
            ></div>
            <div className="battery-image"></div>
            <div className="cloud"></div>
          </div>
        </center>
      </div>
    );
  };
  return (
    <div className="container_wrapper container_wrapper_battery">
      {data?.remaining_distance === "0" && (
        <div className="overlay">
          <div className="overlay__inner">
            <img
              src={
                isFleet
                  ? Icons.orko_loader
                  : isVlektra(vtype)
                  ? Icons.loader
                  : vtype === "sazgar-sazgar"
                  ? Icons.sazgar_loader
                  : Icons.ecodost_loader
              }
              alt="missing graphics"
              className="overlay__content"
              width={"200px"}
            ></img>
          </div>
        </div>
      )}
      <div className="title_battery_level">Battery Level</div>
      <div className={"stats_wrapper"}>
        <Box
          title={"REMAINING RANGE"}
          value={data?.remaining_distance + "km"}
        />

        <Box title={"STATE OF CHARGE"} value={data?.soc + "%"} />
        {/* <Box title={"SOH"} value={data?.state_of_health ? data?.state_of_health + "%" : "N/A"} small /> */}
      </div>
      <div className="charge-level-wrapper">
        {data?.batteries?.soc?.length ? (
          data?.batteries.soc.map((value, index) => {
            return (
              <BatteryUI
                index={index}
                charge={value}
                type={"multi"}
                length={data?.batteries?.soc?.length}
              />
            );
          })
        ) : (
          <BatteryUI
            index={0}
            charge={data?.soc}
            type={"single"}
            status={data?.charging_status}
            length={1}
          />
        )}
      </div>
      {isVlektra(vtype) || isFleet ? <BatteryGraph /> : ""}
    </div>
  );
}
export default Vehicle;
