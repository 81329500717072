import React, { useEffect, useState, useRef } from "react";
import { Icons } from "../assets/icons";
import axios from "axios";
import { isVlektra, sendEventToAndroid, sendEventToiOS } from "../helper";
import GoogleMapStyle from "../helper/googleMap.json";
import {
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
  withScriptjs,
} from "react-google-maps";
import { compose, withProps } from "recompose";
import { GlobalEvents } from "../Utilities/GlobalEvents";
import { useSelector } from "react-redux";

const GoogleMapComponent = compose(
  withProps((props) => ({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_KEY}&timestamp=${props.timestamp}`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100vh` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  })),
  withScriptjs,
  withGoogleMap
)((props) => {
  const [selectedMarker, setSelectedMarker] = useState(null);

  return (
    <GoogleMap
      ref={(map) => props.setMap(map)}
      defaultZoom={props.options.zoom}
      zoom={props.options.zoom}
      onZoomChanged={() => props.onZoomChanged()}
      defaultCenter={props.options.center}
      center={props.options.center}
      defaultOptions={{
        styles: GoogleMapStyle,
        mapTypeControl: false,
        streetViewControl: false,
      }}
    >
      {props.locations &&
        props.locations.length &&
        props.locations.map((marker, i) => {
          if (marker.latitude && marker.longitude)
            return (
              <Marker
                zIndex={marker.primary_key === "vehicle" ? 100 : 3}
                key={i}
                position={{
                  lat: parseFloat(marker.latitude),
                  lng: parseFloat(marker.longitude),
                }}
                icon={{
                  url: marker.src,
                  scaledSize:
                    marker.primary_key === "vehicle"
                      ? new window.google.maps.Size(70, 70)
                      : new window.google.maps.Size(50, 50),
                  origin: new window.google.maps.Point(0, 0),
                  anchor: new window.google.maps.Point(0, 0),
                }}
                onClick={() => {
                  setSelectedMarker(marker);
                  console.log("Marker clicked:", marker.primary_key);
                  if (
                    marker.primary_key !== "vehicle" &&
                    marker.primary_key !== "user"
                  ) {
                    if (sendEventToAndroid("eventStationId")) {
                      window.Android.eventStationId(String(marker.primary_key));
                    } else if (sendEventToiOS("eventStationId")) {
                      window.webkit.messageHandlers.eventStationId.postMessage(
                        String(marker.primary_key)
                      );
                    }
                  }
                }}
              >
                {selectedMarker === marker && marker.primary_key === "user" && (
                  <InfoWindow
                    className="custom-info-window"
                    onCloseClick={() => setSelectedMarker(null)}
                  >
                    <div>Your Location</div>
                  </InfoWindow>
                )}
              </Marker>
            );
        })}
    </GoogleMap>
  );
});

const Map = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const googleMap = useRef();

  const vtype = queryParams.get("vtype");
  const isFleet = queryParams.get("fleet") === "true" ? true : false;
  const token = queryParams.get("token");
  const domain = queryParams.get("domain");

  const state = useSelector((state) => state.pusherSlice);
  const { pusher: data } = state;

  const [markers, setMarkers] = useState([]);
  const [userPosition, setUserPosition] = useState({
    lat: 24.8607,
    lng: 67.0011,
  });
  const [map, setMap] = useState(null);

  const [zoom, setZoom] = useState(15);
  const [isLatLong, setIsLatLong] = useState(false);

  const GOOGLE_MAPS_INVALIDATE_INTERVAL = 12 * 3600 * 1000;
  const timestamp =
    Math.floor(Date.now() / GOOGLE_MAPS_INVALIDATE_INTERVAL) *
    GOOGLE_MAPS_INVALIDATE_INTERVAL;

  const setBoundsInMap = (mapMarkers) => {
    if (window?.google?.maps) {
      const bounds = new window.google.maps.LatLngBounds();
      mapMarkers.forEach((location, i) => {
        if (i < 4 && location.latitude && location.longitude) {
          bounds.extend(
            new window.google.maps.LatLng(
              parseFloat(location.latitude),
              parseFloat(location.longitude)
            )
          );
          if (map && map.fitBounds) map.fitBounds(bounds);
        }
      });
    }
  };

  const [vehiclePacket, setVehiclePacket] = useState(0);

  useEffect(() => {
    if (data?.latitude && data?.longitude && markers[0]) {
      markers[0] = {
        primary_key: "vehicle",
        latitude: data?.latitude,
        longitude: data?.longitude,
        src: Icons[`icon-${vtype && vtype != "null" ? vtype : "vlektra-bolt"}`],
      };
      setMarkers([...markers]);
      if (markers.length && vehiclePacket === 0) {
        setVehiclePacket(1);
        setBoundsInMap(markers);
      }
    }
  }, [data]);

  const getMapCenter = (userLatLong) => {
    if (!data?.latitude && !data?.longitude) {
      if (userLatLong.latitude && userLatLong.longitude) {
        return setUserPosition({
          ...{ lat: userLatLong?.latitude, lng: userLatLong?.longitude },
        });
      }
      return setUserPosition({ ...{ lat: 24.8607, lng: 67.0011 } });
    }
    return setUserPosition({
      ...{ lat: data?.latitude, lng: data?.longitude },
    });
  };

  const getUserCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        let latLong = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        };
        let temp = [
          {
            primary_key: "vehicle",
            latitude: data?.latitude ?? parseFloat(queryParams.get("lat")),
            longitude: data?.longitude ?? parseFloat(queryParams.get("lng")),
            src: Icons[`icon-${vtype || "vlektra-bolt"}`],
          },
          {
            primary_key: "user",
            src: Icons.location,
            ...latLong,
          },
        ];
        setMarkers([...temp]);
        getFuelStations(
          position.coords.latitude,
          position.coords.longitude,
          temp
        );
        getMapCenter(latLong);
        setBoundsInMap([...temp]);
      },
      (err) => {
        let temp = [
          {
            primary_key: "vehicle",
            latitude: data?.latitude ?? null,
            longitude: data?.longitude ?? null,
            src: Icons[`icon-${vtype || "vlektra-bolt"}`],
          },
          {
            primary_key: "user",
            src: Icons.location,
            latitude: null,
            longitude: null,
          },
        ];
        setMarkers([...temp]);
        getFuelStations(data?.latitude, data?.longitude, temp);
        getMapCenter({ latitude: null, longitude: null });
        setBoundsInMap(temp);
      }
    );
  };

  useEffect(() => {
    getUserCurrentLocation();
  }, []);

  useEffect(() => {
    if (map) {
      GlobalEvents.dispatchEvent("SendError", {});
    }
  }, [map]);

  let fuelStationPackets = 0;

  const getFuelStations = (latitude, longitude, temp) => {
    const headers = {
      Authorization: `Bearer ${token}`,
      Domain: domain,
    };

    const url = `${
      process.env.REACT_APP_SERVER
    }/api/v1/charging-station/nearest?latitude=${latitude}&longitude=${longitude}&name=${""}&region=${"pk"}&is_nearest=true`;
    axios
      .get(url, { headers })
      .then((response) => {
        if (response?.data?.body?.stations[0]) {
          let results = response?.data?.body?.stations;
          results.forEach((values) => {
            temp.push({
              primary_key: values?.location_id,
              latitude: values?.location?.lat,
              longitude: values?.location?.long,
              src: isVlektra(vtype) ? Icons.fuelYellow : Icons.fuel,
            });
          });
          setMarkers([...temp]);

          if (temp.length && fuelStationPackets === 0) {
            fuelStationPackets++;
            setBoundsInMap(temp);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLatLong(true);
      });
  };

  return (
    <div style={{ height: "100vh", width: "100%" }}>
      {!isLatLong && (
        <div className="overlay">
          <div className="overlay__inner">
            <img
              src={
                isFleet
                  ? Icons.orko_loader
                  : isVlektra(vtype)
                  ? Icons.loader
                  : vtype === "sazgar-sazgar"
                  ? Icons.sazgar_loader
                  : Icons.ecodost_loader
              }
              className="overlay__content"
              width={"200px"}
              alt="loading"
            ></img>
          </div>
        </div>
      )}
      <div
        onClick={() => {
          if (markers && markers.length) {
            let vehicle = markers.find(
              (value) => value.primary_key === "vehicle"
            );
            if (vehicle) {
              setUserPosition({
                ...{ lat: vehicle?.latitude, lng: vehicle?.longitude },
              });
              setZoom(17);
            }
          }
        }}
        style={{
          position: "absolute",
          top: "78%",
          zIndex: 1000,
          right: "10px",
        }}
      >
        <img
          style={{ width: 40, height: 40 }}
          alt="current location"
          src={Icons.currentLocation}
        ></img>
      </div>
      <GoogleMapComponent
        id="myMap"
        timestamp={timestamp}
        options={{
          center: userPosition,
          zoom: zoom,
          mapTypeId: "mystyle",
        }}
        locations={markers}
        onZoomChanged={() => {
          setZoom(map.getZoom());
        }}
        mapRef={googleMap}
        setMap={(a) => setMap(a)}
      />
    </div>
  );
};

export default Map;
