import { useState } from "react";
import CameraPhoto from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import { Icons } from "../../assets/icons";
import "./Camera.scss";

const Camera = ({ onPhotoTaken, onError }) => {
  const [dataUri, setDataUri] = useState("");
  const [isCameraOpen, setIsCameraOpen] = useState(true); // State to control camera visibility
  const [facingMode, setFacingMode] = useState("environment"); // Default to back camera

  // Handle photo capture
  const handleTakePhoto = (dataUri) => {
    console.log("Photo taken:", dataUri);
    setDataUri(dataUri);
    setIsCameraOpen(false); // Close camera after taking photo
    if (onPhotoTaken) onPhotoTaken(dataUri); // Notify parent component
  };

  // Handle errors
  const handleError = (error) => {
    console.error("Error:", error);
    if (onError) onError(error); // Notify parent component
  };

  // Toggle camera facing mode
  const toggleCamera = () => {
    setFacingMode((prevMode) =>
      prevMode === "environment" ? "user" : "environment"
    );
  };

  // Handle camera toggle button click
  const handleCameraToggle = () => {
    setIsCameraOpen((prev) => !prev); // Toggle camera visibility
  };

  return (
    <div className={`camera-container ${isCameraOpen ? "fullscreen" : ""}`}>
      {isCameraOpen ? (
        <>
          <CameraPhoto
            className="my-camera"
            onTakePhoto={handleTakePhoto}
            onError={handleError}
            idealFacingMode={facingMode}
            isImageMirror={false}
            imageType="jpg"
            imageCompression={0.97}
            isFullscreen={true}
          />
          <button
            className="toggle-camera-btn switch-camera-btn"
            onClick={toggleCamera}
          >
            <img src={Icons.camera_toggle} width="20px" alt="z" />
          </button>
          <button
            className="toggle-camera-btn close-btn"
            onClick={handleCameraToggle}
          >
            <img src={Icons.close} width="20px" alt="x" />
          </button>
        </>
      ) : (
        <div className="photo-preview">
          {dataUri && <img src={dataUri} alt="Captured" />}
          {/* <button className="toggle-camera-btn" onClick={handleCameraToggle}>
            Open Camera
          </button> */}
        </div>
      )}
    </div>
  );
};

export default Camera;
