import moment from "moment";
import { Line } from "react-chartjs-2";
import { isVlektra } from "../../helper";
import './index.scss';

const staticLabels = Array(6)
    .fill("")
    .map((item, index) => {
        item = moment().subtract(index, "hours").format("hh:00A");
        return item;
    });

const StaticGraph = ({ loading }) => {
    const queryParams = new URLSearchParams(window.location.search);
    const vtype = queryParams.get("vtype");

    const graphOptions = {

        parsing: {
            xAxisKey: "timestamp",
            yAxisKey: "reading",
        },
        scales: {
            y: {

                ticks: {
                    // source: "data",
                    // beginAtZero: true,
                    font: {
                        size: 9,
                    },
                    padding: 5,
                    color: "#6b6a6d",
                },
                title: {
                    display: true,
                    text: 'SOC',
                    font: {
                        size: 9
                    },
                    padding: {
                        bottom: 0
                    }
                },
                // grid: {
                //     display: false,
                // },
            },
            x: {
                // type: "time",
                // time: {
                //   unit: "month",
                //   // minUnit: "month",
                //   displayFormats: {
                //     month: "YYYY MMM",
                //   },
                //   // min: moment().format("x"),
                //   // max: moment().subtract(6, "months").format("x"),
                // },
                ticks: {
                    source: "labels",
                    // For a category axis, the val is the index so the lookup via getLabelForValue is needed
                    // callback(val) {
                    //     const format = getFormat();

                    //     // Hide the label of every 2nd dataset
                    //     return moment(this.getLabelForValue(val)).format(format).split(",");
                    // },
                    font: {
                        size: 9,
                    },
                    color: "#6b6a6d",
                },
                title: {
                    display: true,
                    text: 'Time',
                    font: {
                        size: 9
                    },
                    // padding: {
                    //     bottom: 0
                    // }
                },
                grid: {
                    display: false,
                },
            },
        },
        plugins: {
            legend: { display: false },
            autocolors: false,
        },
    };

    const data = {
        labels: staticLabels,
        datasets: [
            {
                data: [],
                fill: false,
                // borderColor: variables.turquoiseBlue,
                // tension: 0.5,
            },
        ],
    };
    return (
        <div className="no-data-grap-container">
            {loading ? (
                <div className={`spinner ${isVlektra(vtype) ? "isVlectra" : ""}`}>
                    <span className="spinner-inner-1"></span>
                    <span className="spinner-inner-2"></span>
                    <span className="spinner-inner-3"></span>
                </div>
            ) : (
                <div className="ovelay">
                    <span className="no-data">No Data Uploaded</span>
                </div>
            )}
            <Line data={data} options={graphOptions} />
        </div>
    );
};

export default StaticGraph;