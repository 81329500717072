import React, { memo, useEffect, useState } from "react";
import { Icons } from "../assets/icons";
import { useNavigate } from "react-router-dom";
// import { usePusher } from "../helper/Pusher/usePusher";
import {
  isVlektra,
  sendEventToAndroid,
  sendEventToiOS,
  vlektraBackgroundColor,
} from "../helper";
import { useSelector } from "react-redux";

function Vehicle() {
  const queryParams = new URLSearchParams(window.location.search);
  const vtype = queryParams.get("vtype");
  const isFleet = queryParams.get("fleet") === "true" ? true : false;
  const cid = queryParams.get("cid");
  // // const pusher = queryParams.get("pusher");
  const domain = queryParams.get("domain");
  const token = queryParams.get("token");

  const state = useSelector((state) => state.pusherSlice);
  const { pusher: data, address } = state;

  // const [address, setAddress] = useState("")
  // const { data, setData } = usePusher(
  //   pusher || localStorage.getItem("pusher") ? JSON.parse(pusher) || JSON.parse(localStorage.getItem("pusher"))
  //     : null,
  //   props.pusher
  // );
  // useEffect(() => {
  //   setData(pusher || localStorage.getItem("pusher") ? JSON.parse(pusher) || JSON.parse(localStorage.getItem("pusher"))
  //     : null)
  // }, [])
  // const { data } = usePusher();
  // console.log("props.pusher", pusher)

  // const getDataFromMobile = (data) => {
  //   console.log("PUSHER AND ADDRESS DATA:", data?.detail?.address);
  //   console.log("PUSHER AND ADDRESS DATA:", data?.detail?.pusher);

  //   const pusher = JSON.parse(data?.detail?.pusher);

  //   console.log("pusher:", pusher);

  //   if (data?.detail?.address) {
  //     setAddress(data?.detail?.address ?? "")
  //   }
  //   console.log("Boolean(data?.detail?.pusher)",Boolean(data?.detail?.pusher));
  //   if (Boolean(data?.detail?.pusher)) {
  //     PusherActions.setPusherData(pusher);
  //   }
  // }
  // useEffect(() => {
  //   window.addEventListener("onChangedPusher", getDataFromMobile);

  //   return () => {
  //     window.removeEventListener("onChangedPusher", getDataFromMobile);

  //   }

  // }, [])

  // const addEvent = (name, payload) => {
  //   let event = new CustomEvent(name, payload);
  //   let eventData = { name, event };
  //   // this.events.push(eventData);
  //   return eventData;
  // };

  // const dispatchEvent = (name, payload) => {
  //   let { event } = addEvent(name, { detail: payload });

  //   console.log("event", event);
  //   if (event) {
  //     window.dispatchEvent(event)
  //   }
  // }

  // useEffect(() => {
  //   dispatchEvent("onChangedPusher", { address: "abc.adress", pusher: `{"_id":"647dcdb04088c893a8c706cc","domain":"Vlektra","latitude":24.815583,"longitude":67.022093,"realtime_location":true,"ident":"350612070215551","soc":83,"state_of_health":"","remaining_capacity":"","full_capacity":"","remaining_distance":"74.7","fuel_economy":"0.63","vehicle_status":"Normal","batteries":{},"timestamp":"2023-06-05 11:57:40","daily_distance":0,"total_distance_travelled":"85","emission_saving":"0.70","cumulative_emission_saving":"1.88","tree_saved":"2","total_fuel_cost":947.32,"total_electric_cost":67.49,"total_fuel_savings":879.83}` })
  // }, [])

  // useEffect(() => {
  //   localStorage.setItem("pusher", JSON.stringify(data))
  // }, [data])

  let navigate = useNavigate();

  // const getGeocodeData = (latitude, longitude, pusherData) =>
  // {
  //   if (count < 4)
  //   {
  //     const apiKey = "$2a$10$GaR4ioDvpMSB5lkpKpXOgernA42HfUYuP0eAmGGth0fk799zIpX2C"
  //     count++
  //     const url = `https://api1.tplmaps.com:8888/search/rgeocode?apikey=${apiKey}&point=${Number(latitude)};${Number(longitude)}`;
  //     fetch(url)
  //       .then((response) => response.json())
  //       .then((googleData) =>
  //       {
  //         setData({
  //           ...pusherData,
  //           address: googleData[0]?.compound_address_parents || "",
  //         });
  //         // Do something with the response data
  //       })
  //       .catch((error) =>
  //       {
  //         // Handle any errors that occur during the fetch request
  //         console.error(error);
  //       });
  //   }
  // };
  // useEffect(() =>
  // {
  //   if (data?.latitude && data?.longitude)
  //     getGeocodeData(Number(data?.latitude), Number(data?.longitude));
  // }, []);

  useEffect(() => {
    if (sendEventToAndroid("onHomeScreen")) {
      window.Android.onHomeScreen(String("onHomeScreen"));
    } else if (sendEventToiOS("onHomeScreen")) {
      window.webkit.messageHandlers.onHomeScreen.postMessage("onHomeScreen");
    }
  }, []);

  const OnClickfindMyBike = () => {
    if (sendEventToAndroid("eventFindMyBike")) {
      window.Android.eventFindMyBike(
        String(
          `https://${window.location.host}/ChargingStation?vtype=${vtype}&fleet=${isFleet}&lat=${data.latitude}&lng=${data.longitude}&cid=${cid}&token=${token}&domain=${domain}`
        ),
        String(
          `Find My ${
            isVlektra(vtype) || isFleet
              ? "Motorcycle"
              : vtype === "sazgar-sazgar"
              ? "Sazgar"
              : "Ecodost"
          }`
        )
      );
    } else if (sendEventToiOS("eventFindMyBike")) {
      window.webkit.messageHandlers.eventFindMyBike.postMessage(
        String(
          `https://${window.location.host}/ChargingStation?vtype=${vtype}&fleet=${isFleet}&lat=${data.latitude}&lng=${data.longitude}&cid=${cid}&token=${token}&domain=${domain}`
        ),
        String(
          `Find My ${
            isVlektra(vtype)
              ? "Motorcycle"
              : vtype === "sazgar-sazgar"
              ? "Sazgar"
              : "Ecodost"
          }`
        )
      );
    }
    return navigate(
      `/ChargingStation?vtype=${vtype}&fleet=${isFleet}&lat=${data.latitude}&lng=${data.longitude}&token=${token}&domain=${domain}&cid=${cid}`
    );
  };
  let bg = {
    // backgroundColor: isVlektra(vtype) ? vlektraBackgroundColor : "#ddf3f1",
    backgroundColor: isFleet
      ? "#ddf3f1"
      : isVlektra(vtype)
      ? vlektraBackgroundColor
      : "#ddf3f1",
  };

  const onClickBatter = () => {
    // if (sendEventToAndroid("eventBatteryManagementScreen")) {
    //   window.Android.eventBatteryManagementScreen(
    //     String(
    //       `https://${window.location.host}/BatteryManagement?vtype=${vtype}&fleet=${isFleet}&cid=${cid}`
    //     ),
    //     String("Battery Management")
    //   );
    // } else if (sendEventToiOS("eventBatteryManagementScreen")) {
    //   window.webkit.messageHandlers.eventBatteryManagementScreen.postMessage(
    //     String(
    //       `https://${window.location.host}/BatteryManagement?vtype=${vtype}&fleet=${isFleet}&cid=${cid}`
    //     ),
    //     String("Battery Management")
    //   );
    // }
    return navigate(
      `/BatteryManagement?vtype=${vtype}&fleet=${isFleet}&cid=${cid}`
    );
  };

  const onClickTotalDistance = () => {
    console.log(
      isVlektra(vtype),
      vtype == "nova_mobility-electric_3_wheeler" && isFleet
    );
    if (
      isVlektra(vtype) ||
      vtype === "nova_mobility-electric_3_wheeler" ||
      vtype === "nova_mobility-ecodost_electric_2_wheeler" ||
      vtype === "mustang-mustang_-_model"
    ) {
      if (sendEventToAndroid("eventTotalDistanceScreen")) {
        window.Android.eventTotalDistanceScreen(
          String(
            `https://${window.location.host}/TotalDistance?vtype=${vtype}&fleet=${isFleet}&cid=${cid}`
          ),
          String("Total Distance")
        );
      } else if (sendEventToiOS("eventTotalDistanceScreen")) {
        window.webkit.messageHandlers.eventTotalDistanceScreen.postMessage(
          String(
            `https://${window.location.host}/TotalDistance?vtype=${vtype}&fleet=${isFleet}&cid=${cid}`
          ),
          String("Total Distance")
        );
      }
      return navigate(
        `/TotalDistance?vtype=${vtype}&fleet=${isFleet}&cid=${cid}`
      );
    }
  };
  // const OldBattery = () =>
  // {
  //   return (
  //     <div
  //       className="battery_wrapper"
  //       onClick={() =>
  //       {
  //         onClickBatter()
  //       }}
  //     >
  //       <div style={{ letterSpacing: 2 }} className="battery_title">BATTERY</div>
  //       <div className="battery_percentage">{data?.soc}%</div>
  //       <div className="trapezoid" style={{
  //         borderBottom: `20px solid ${isVlektra(vtype) ? "#505050" : "#1CADA1"}`
  //       }}>
  //         <div>
  //           <div
  //             className="battery_filled"
  //             style={{
  //               width: data?.soc + "%",
  //               background: isVlektra(vtype) ? "#f0c900" : "#1CADA1"
  //             }}
  //           ></div>
  //           <div className="battery-tick-container">
  //             <div className="battery-tick"></div>
  //             <div className="battery-tick"></div>
  //             <div className="battery-tick"></div>
  //             <div className="battery-tick"></div>
  //             <div className="battery-tick"></div>
  //             <div className="battery-tick"></div>
  //             <div className="battery-tick"></div>
  //             <div className="battery-tick"></div>
  //             <div className="battery-tick"></div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>

  //   )
  // }
  const NewBattery = () => {
    return (
      <div>
        <div
          className="battery_wrapper"
          onClick={() => {
            onClickBatter();
          }}
        >
          <div className="battery_title">BATTERY</div>
          <div className="battery_percentage">{data?.soc}%</div>
          <div className="trapezoid">
            <div>
              <div
                className="battery_filled"
                style={{
                  width: data?.soc + "%",
                  background: isVlektra(vtype)
                    ? isFleet
                      ? "#1CADA1"
                      : "#f0c900"
                    : "#1CADA1",
                }}
              ></div>
              <div className="battery-tick-container">
                <div className="battery-tick"></div>
                <div className="battery-tick"></div>
                <div className="battery-tick"></div>
                <div className="battery-tick"></div>
                <div className="battery-tick"></div>
                <div className="battery-tick"></div>
                <div className="battery-tick"></div>
                <div className="battery-tick"></div>
                <div className="battery-tick"></div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="battery_wrapper bottom-battery-wrapper"
          onClick={() => {
            onClickBatter();
          }}
        >
          <div
            className="trapezoid bottom-battery"
            style={{
              borderBottom: `20px solid ${
                isVlektra(vtype) ? (isFleet ? "#1CADA1" : "#505050") : "#1CADA1"
              }`,
            }}
          >
            <div>
              <div
                className="battery_filled"
                style={{
                  width: data?.soc + "%",
                  background: isVlektra(vtype)
                    ? isFleet
                      ? "#1CADA1"
                      : "#f0c900"
                    : "#1CADA1",
                }}
              ></div>
              <div className="battery-tick-container">
                <div className="battery-tick"></div>
                <div className="battery-tick"></div>
                <div className="battery-tick"></div>
                <div className="battery-tick"></div>
                <div className="battery-tick"></div>
                <div className="battery-tick"></div>
                <div className="battery-tick"></div>
                <div className="battery-tick"></div>
                <div className="battery-tick"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="container_wrapper">
      {!Object.keys(data || {}).length && (
        <div className="overlay">
          <div className="overlay__inner">
            <img
              src={
                isFleet
                  ? Icons.orko_loader
                  : isVlektra(vtype)
                  ? Icons.loader
                  : vtype === "sazgar-sazgar"
                  ? Icons.sazgar_loader
                  : Icons.ecodost_loader
              }
              className="overlay__content"
              width={"200px"}
              alt="missing graphics"
            ></img>
          </div>
        </div>
      )}
      <div className="vehicle_stats_wrapper">
        <div
          className="vehicle_stats"
          style={{
            ...bg,
          }}
        >
          <div className="vehicle_status_wrapper">
            <div>VEHICLE STATUS</div>
            <div>{data?.vehicle_status}</div>
          </div>
          {/* <div style={{ paddingLeft: 10 }}>{">"}</div> */}
        </div>
        {isFleet ? (
          ""
        ) : domain === "Ecodost" ? (
          ""
        ) : (
          <div
            className="emissions_savings_wrapper"
            style={{
              ...bg,
            }}
            onClick={() => {
              if (sendEventToAndroid("eventEmissionScreen")) {
                window.Android.eventEmissionScreen(
                  String(
                    `https://${window.location.host}/Emission?vtype=${vtype}&fleet=${isFleet}&cid=${cid}`
                  ),
                  String("Carbon Savings")
                );
              } else if (sendEventToiOS("eventEmissionScreen")) {
                window.webkit.messageHandlers.eventEmissionScreen.postMessage(
                  String(
                    `https://${window.location.host}/Emission?vtype=${vtype}&fleet=${isFleet}&cid=${cid}`
                  ),
                  String("Carbon Savings")
                );
              }
              return navigate(
                `/Emission?vtype=${vtype}&fleet=${isFleet}&cid=${cid}`
              );
            }}
          >
            CARBON SAVINGS
            <div>{">"}</div>
          </div>
        )}
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img
          src={Icons[vtype] || Icons.bike_placeholder}
          width={"80%"}
          alt={"car-logo"}
        />
      </div>
      <div className="location_wrapper">
        <img src={Icons.vector} width={"5%"} alt={"location_icon"} />
        <div>{address || ""}</div>
      </div>
      {/* <OldBattery /> */}
      {data.vehicle_tech !== "ice" && <NewBattery />}
      <div className="eco_dost_container">
        <div className="find_ecodost" onClick={OnClickfindMyBike}>
          <div>
            FIND MY{" "}
            {isVlektra(vtype) || isFleet
              ? data.vehicle_tech !== "ice"
                ? "MOTORCYCLE"
                : "VEHICLE"
              : vtype === "sazgar-sazgar"
              ? "SAZGAR"
              : "ECODOST"}
          </div>
          <img
            src={
              isVlektra(vtype)
                ? isFleet
                  ? Icons.vehicleGreen
                  : Icons.vehicleYellow
                : Icons.vehicle
            }
            width={"50px"}
            className="fuel_icon"
            alt={"vehicle-logo"}
          />
        </div>
        {data.vehicle_tech !== "ice" && (
          <div className="remaining_container">
            <div>CURRENT RANGE</div>
            <div className="row-flex-center">
              <div className="kiloneter_value">
                {data?.remaining_distance || 0}
              </div>
              <div className="kiloneter_container">km</div>
            </div>
          </div>
        )}
      </div>
      <div className="eco_dost_container">
        <div className="find_ecodost">
          <div>TOTAL DISTANCE</div>
          <div
            onClick={onClickTotalDistance}
            className={`kiloneter_value${
              isVlektra(vtype)
                ? isFleet
                  ? " vehicle_isfleet"
                  : " kiloneter_value_vlek"
                : vtype === "nova_mobility-electric_3_wheeler" ||
                  vtype === "nova_mobility-ecodost_electric_2_wheeler" ||
                  vtype === "mustang-mustang_-_model"
                ? " vehicle_isfleet"
                : ""
            }`}
          >{`${
            (data?.total_distance_travelled &&
              data?.total_distance_travelled
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")) ||
            0
          }kms`}</div>
        </div>
        <div className="remaining_container">
          <div>TODAY's DISTANCE</div>
          <div className="row-flex-center">
            <div className="kiloneter_value">{`${
              (data?.daily_distance &&
                data?.daily_distance
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")) ||
              0
            }kms`}</div>
          </div>
        </div>
      </div>
      <div className="container_vehicle">
        <img src={Icons.ignation} alt="fuel-logo" />
        <div className="box_vehicle">
          <div>
            <div>IGNITION </div>
            <div>
              {data?.ignition_status ? (
                <span
                  className={
                    isVlektra(vtype)
                      ? isFleet
                        ? "text-green"
                        : "text-yellow"
                      : "text-green"
                  }
                >
                  ON
                </span>
              ) : (
                <span className="">OFF</span>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="container_vehicle">
        <img src={Icons.subtract} alt="fuel-logo" />
        <div className="box_vehicle">
          <div>
            <div>ECONOMY</div>
            <div>{data?.fuel_economy} Rs/km</div>
          </div>
        </div>
      </div>
      {(isVlektra(vtype) || vtype === "sazgar-sazgar" || isFleet) &&
        data.vehicle_tech !== "ice" && (
          <div className="container_vehicle margin-bottom">
            <img src={Icons.saving} alt="fuel-logo" />
            <div className="box_vehicle">
              <div>
                <div>FUEL COST SAVINGS</div>
                <div>
                  Rs{" "}
                  {(data?.total_fuel_cost &&
                    Math.round(data?.total_fuel_cost)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")) ||
                    0}
                </div>
              </div>
            </div>
          </div>
        )}
    </div>
  );
}
export default memo(Vehicle);
