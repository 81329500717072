export enum TimeFilterValue {
    consumed,
    depleted,
}


export type TDifferenceIn = "months" | "weeks" | "days" | "hours" | "years";

export interface IReadingData {
    total_energy_consumption: number;
    total_spent: number;
    total_charged: number;
    total_saving: number;
    readings: any[];
}