// import moment from 'moment';
import moment from "moment";
import { memo, useEffect, useState } from "react";
import { REACT_APP_STORAGE_URL } from "../../constants";
import {
  IReadingData,
  TimeFilterValue,
} from "../../Entities/Interfaces/IGraphs";
import { formatter, getCurrentTimestamp, isVlektra } from "../../helper";
import {
  getBatteryReadings,
  getEnergyDepletedReadings,
  getFilteredReading,
} from "../../helper/GraphHelpers";
import BarGraph from "../BarGraph/BarGraph";
import LineGraph from "../LineGraph/LineGraph";
import DateFilterButtons from "../DateFilterButtons";
import "./index.scss";

export interface IBatteryGraphProps {
  backgroundColor: string;
}

// const readings: any[] = require('../../data/data.json');

function BatteryGraph(props: IBatteryGraphProps) {
  const queryParams = new URLSearchParams(window.location.search);
  const vtype = queryParams.get("vtype");
  const isFleet = queryParams.get("fleet") === "true" ? true : false;
  const cid = queryParams.get("cid");

  const bg = {
    backgroundColor: isVlektra(vtype)
      ? isFleet
        ? "#189a8f"
        : "#d5b300"
      : "#189a8f",
  };

  // console.log("readings:", readings);
  const [graphReading, setGraphReading] = useState<IReadingData>({
    total_spent: 0,
    total_charged: 0,
    total_saving: 0,
    total_energy_consumption: 0,
    readings: [],
  });
  const [energyDepletedGraphReading, setEnergyDepletedGraphReading] =
    useState<any>([]);
  // const [tripsGraphReading, setTripsGraphReading] = useState<any>([]);

  const [socGraphReading, setSocGraphReading] = useState<any>([]);
  const [socGraphLoading, setSocGraphLoading] = useState<any>(true);

  // const [totalData, setTotalData] = useState<any>([]);
  const [timeFilterValue, setTimeFilterValue] = useState<any>("");
  // console.log("readings:", graphReading);
  console.log("timeFilterValue:", timeFilterValue);

  useEffect(() => {
    getReadingsFile();
    getSocReadingsFile();
  }, []);

  async function downloadAndExtractJson(url: RequestInfo | URL) {
    const arr: any = await fetch(url, {
      method: "GET",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
      },
      // referrerPolicy: "no-referrer",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        // Process the extracted JSON data
        console.log("Api res:", data);
        return data;
      })
      .catch((error) => {
        console.error("Error:", error);
        return [];
      });
    return arr;
  }

  const getReadingsFile = async () => {
    // console.log("REACT_APP_STORAGE_URL", REACT_APP_STORAGE_URL);
    const timestamp = getCurrentTimestamp();

    const path = `${REACT_APP_STORAGE_URL}vehicle_charge_sessions/a_${cid}.json?timestamp=${timestamp}`;
    console.log("path: ", path);

    const readings = await downloadAndExtractJson(path);

    const readingData = getBatteryReadings(readings?.charge_sessions || []);
    const energyDepletedReadings = getEnergyDepletedReadings(
      readings?.energy_depleted || [],
      8
    );
    // const tripsData = getTripsReadings(readings?.trips || [], 7);

    // console.log("tripsData:", tripsData);

    console.log("readingData:", readingData);
    console.log("energyDepletedReadings:", energyDepletedReadings);
    setEnergyDepletedGraphReading(energyDepletedReadings);
    // setTripsGraphReading(tripsData);
    // setTotalData(readingData);

    const filterdReading = getFilteredReading(readingData, 8);
    console.log("filterdReading:", filterdReading);

    setGraphReading(filterdReading);
  };

  const getSocReadingsFile = async () => {
    // console.log("REACT_APP_STORAGE_URL", REACT_APP_STORAGE_URL);
    const timestamp = getCurrentTimestamp();

    const path = `${REACT_APP_STORAGE_URL}soc_data/b_${cid}.json?timestamp=${timestamp}`;
    console.log("path: ", path);

    const readings = await downloadAndExtractJson(path);

    // readings.sort((x: { [x: string]: number }, y: { [x: string]: number }) => {
    //   const yDate = moment(y.date_time_gmt_05).format("X");
    //   const xDate = moment(x.date_time_gmt_05).format("X");
    //   return Number(yDate) - Number(xDate);
    // });

    console.log("Soc readings", readings);
    setSocGraphReading(readings);
    setSocGraphLoading(false);
  };

  const onFilterChange = (key: any) => {
    console.log(key);
    if (TimeFilterValue[key] === timeFilterValue) return setTimeFilterValue("");

    setTimeFilterValue(TimeFilterValue[key]);
  };

  return (
    <div id="battery-graph-container">
      {isVlektra(vtype) ? (
        <div>
          <h1>Weekly Charging Stats</h1>
          <DateFilterButtons
            timeFilterButtons={["Consumed", "Depleted"]}
            onFilterChange={onFilterChange}
            timeFilterValue={timeFilterValue}
            vtype={vtype}
            // disabled={trendLoading}
          />
          <div className="total-data">
            {/* <h2>Last {moment().daysInMonth()} Days</h2> */}
            <div className="total-data-stats">
              <div>
                <h3>
                  Total Energy<br></br> Consumed
                </h3>
                <h4>
                  {graphReading.total_energy_consumption?.toFixed(2) || 0} kWh
                </h4>
              </div>
              <div>
                <h3>Total Spent</h3>
                <h4>{formatter.format(graphReading.total_spent || 0)}</h4>
              </div>
              <div>
                <h3>Total Savings</h3>
                <h4>{formatter.format(graphReading.total_saving || 0)}</h4>
              </div>
            </div>
          </div>
          <BarGraph
            readings={graphReading.readings}
            energyDepletedGraphReading={energyDepletedGraphReading}
            backgroundColor={bg.backgroundColor}
            vtype={vtype}
            cid={cid}
            timeFilterValue={timeFilterValue}
          />

          {/* <div className='trip-graph'>
                <h1>Weekly Trips Stats</h1>
                <BarGraph readings={tripsGraphReading} energyDepletedGraphReading={energyDepletedGraphReading} backgroundColor={bg.backgroundColor} vtype={vtype} cid={cid} timeFilterValue="trips" />
            </div> */}
        </div>
      ) : (
        ""
      )}
      <div className="soc-graph">
        <h1>Daily SOC Stat</h1>
        <LineGraph
          readings={socGraphReading}
          backgroundColor={bg.backgroundColor}
          // backgroundColor='red'
          loading={socGraphLoading}
        />
      </div>
    </div>
  );
}
export default memo(BatteryGraph);
