import { IDataFromMobile } from "../../Entities/Interfaces/IDataFromMobile";
import { LocalDB } from "../../Utilities/LocalDB";
import { PusherType } from "./pusherReducer";
import { setData } from "../../store/slices/pusherSlice";
import store from "../../store/store";
export class PusherActions {
  static dispatch: any;

  static init(dispatch: any) {
    this.dispatch = store.dispatch;
  }

  static setPusherData(data: IDataFromMobile) {
    // this.dispatch({ type: PusherType.SetData, payload: data });
    this.dispatch(setData(data));

    if (Object.keys(data.pusher).length) {
      LocalDB.set("pusher", JSON.stringify(data.pusher));
    }
    LocalDB.set("address", data.address);
  }

  static getPusherDataFormLocalDB(cid: undefined) {
    const pusherLocalStorage = LocalDB.get("pusher");
    const address = LocalDB.get("address");
    if (pusherLocalStorage) {
      const pusher = JSON.parse(pusherLocalStorage);
      if (cid !== pusher.ident) {
        return LocalDB.clear();
      }
      this.dispatch(setData({ pusher, address }));
    }
  }
}
