import axios from "axios";

import {
  IGetRequestProps,
  IPostRequestProps,
  IDeleteRequestProps,
} from "./types";

export const get = async ({ url, options }: IGetRequestProps) => {
  console.log("options:",options);
  
  return axios.get(url, options);
};

export const post = async ({ url, body, options }: IPostRequestProps) => {
  if (options) {
    return axios.post(url, body, options);
  }
  return axios.post(url, body);
};

export const put = async ({ url, body }: IPostRequestProps) => {
  return axios.put(url, body);
};

export const destroy = async ({ url, body }: IDeleteRequestProps) => {
  return axios.delete(url, { data: body });
};
