// import moment from 'moment';
import { memo, useEffect, useState } from "react";
import { REACT_APP_STORAGE_URL } from "../../constants";
import { getCurrentTimestamp, isVlektra } from "../../helper";
import { getTripsReadings } from "../../helper/GraphHelpers";
import BarGraph from "../BarGraph/BarGraph";
// import "./index.scss"

export interface IBatteryGraphProps {
  backgroundColor: string;
}

// const readings: any[] = require('../../data/data.json');

function BatteryGraph(props: IBatteryGraphProps) {
  const queryParams = new URLSearchParams(window.location.search);
  const vtype = queryParams.get("vtype");
  const isFleet = queryParams.get("fleet") === "true" ? true : false;

  const cid = queryParams.get("cid");

  const bg = {
    backgroundColor: isVlektra(vtype)
      ? isFleet
        ? "#189a8f"
        : "#d5b300"
      : "#189a8f",
  };

  // console.log("readings:", readings);
  const [tripsGraphReading, setTripsGraphReading] = useState<any>([]);

  // const [totalData, setTotalData] = useState<any>([]);
  console.log("tripsGraphReading:", tripsGraphReading);

  useEffect(() => {
    console.log("tripsGraphReading.length", tripsGraphReading.length);

    if (!tripsGraphReading.length) {
      getReadingsFile();
    }
  }, []);

  async function downloadAndExtractJson(url: RequestInfo | URL) {
    const arr: any = await fetch(url, {
      method: "GET",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        // Process the extracted JSON data
        console.log("Api res:", data);
        return data;
      })
      .catch((error) => {
        console.error("Error:", error);
        return [];
      });
    return arr;
  }

  const getReadingsFile = async () => {
    // console.log("REACT_APP_STORAGE_URL", REACT_APP_STORAGE_URL);
    const timestamp = getCurrentTimestamp();

    const path = `${REACT_APP_STORAGE_URL}vehicle_charge_sessions/a_${cid}.json?timestamp=${timestamp}`;
    console.log("path: ", path);

    const readings = await downloadAndExtractJson(path);

    const tripsData = getTripsReadings(readings?.trips || [], 8);

    console.log("tripsData:", tripsData);

    setTripsGraphReading(tripsData);
    // setTotalData(readingData);
  };

  return (
    <div id="battery-graph-container">
      <div className="trip-graph">
        <h1>Weekly Trips Stats</h1>
        <BarGraph
          readings={tripsGraphReading}
          energyDepletedGraphReading={[]}
          backgroundColor={bg.backgroundColor}
          vtype={vtype}
          cid={cid}
          timeFilterValue="trips"
        />
      </div>
    </div>
  );
}
export default memo(BatteryGraph);
