
import React from "react";
import { SeverityLevel } from "@microsoft/applicationinsights-common";
import { isDev } from "../helper";

export interface IUnexpectedCrashScreenProps {
    appInsights: any
    onError: React.ComponentType<any>
    children: React.ReactElement
}

export interface IUnexpectedCrashScreenState {
    hasError: boolean
}

export default class UnexpectedCrashScreen extends React.Component<IUnexpectedCrashScreenProps, IUnexpectedCrashScreenState> {
    state = { hasError: false };

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        this.setState({ hasError: true });
        if (isDev()) return;
        this.props.appInsights.trackException({
            error: error,
            exception: error,
            severityLevel: SeverityLevel.Error,
            properties: errorInfo
        }, { module: "React Application" });
    }

    render() {
        if (this.state.hasError) {
            const { onError } = this.props;
            return React.createElement(onError);
        }

        return this.props.children;
    }
}