import { combineReducers, configureStore } from "@reduxjs/toolkit";
import pusherSlice from "./slices/pusherSlice";
import alertsSlice from "./slices/alertsSlice";

const reducer = combineReducers({
  pusherSlice: pusherSlice,
  alertsSlice: alertsSlice,
});

export default configureStore({
  reducer: reducer,
});
