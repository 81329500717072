import { useRef } from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  // Legend,
} from "chart.js";
import { Bar, getDatasetAtEvent, getElementAtEvent } from "react-chartjs-2";
import moment from "moment";
// import GraphModal from '../GraphModal/GraphModal';
import { useNavigate } from "react-router-dom";
import { externalTooltipHandlerBarChart } from "../../helper/GraphHelpers";
import { sendEventToAndroid, sendEventToiOS } from "../../helper";
import { TimeFilterValue } from "../../Entities/Interfaces/IGraphs";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip
  // Legend
);

// export interface IBarGraphProps {
//     readings: any
//     timeFilterValue?: any
// }

function BarGraph({
  readings,
  energyDepletedGraphReading,
  backgroundColor,
  vtype,
  cid,
  timeFilterValue,
}) {
  const chartRef = useRef();
  const navigate = useNavigate();
  const isTripChart = timeFilterValue === "trips";

  // const getData = () => {
  let dataset;
  let YAxisTitle;

  if (timeFilterValue === TimeFilterValue[0]) {
    dataset = [
      {
        type: "bar",
        data: readings,
        borderColor: backgroundColor,
        backgroundColor: backgroundColor,
        borderRadius: { topLeft: 5, topRight: 5 },
        barThickness: 7,
        parsing: {
          yAxisKey: "energy_consumption_per_day",
        },
      },
    ];
    YAxisTitle = "Energy Consumed";
  } else if (timeFilterValue === TimeFilterValue[1]) {
    dataset = [
      {
        type: "bar",
        data: energyDepletedGraphReading,
        borderColor: "#888785",
        backgroundColor: "#888785",
        borderRadius: { topLeft: 5, topRight: 5 },
        barThickness: 7,
        // barPercentage: 1,
        parsing: {
          yAxisKey: "energy_consumption",
        },
      },
    ];
    YAxisTitle = "Energy Depleted";
  } else if (timeFilterValue === "trips") {
    dataset = [
      {
        type: "bar",
        data: readings,
        borderColor: backgroundColor,
        backgroundColor: backgroundColor,
        borderRadius: { topLeft: 5, topRight: 5 },
        barThickness: 7,
        parsing: {
          yAxisKey: "distance_per_day",
        },
      },
    ];
    YAxisTitle = "Trips Duration";
  } else {
    dataset = [
      {
        type: "bar",
        data: readings,
        borderColor: backgroundColor,
        backgroundColor: backgroundColor,
        borderRadius: { topLeft: 5, topRight: 5 },
        barThickness: 7,
        parsing: {
          yAxisKey: "energy_consumption_per_day",
        },
        // categoryPercentage: 1.0,
        // barPercentage: 0.5,
        // borderSkipped: true,
        // tension: 0.5,
        // pointBackgroundColor: colorRange,
        // pointBorderColor: "#fff",
        // hoverBorderColor: variables.colorRange,
      },
      {
        type: "bar",
        data: energyDepletedGraphReading,
        borderColor: "#888785",
        backgroundColor: "#888785",
        borderRadius: { topLeft: 5, topRight: 5 },
        // barThickness: 6,
        parsing: {
          yAxisKey: "energy_consumption",
        },
        categoryPercentage: 1.0,
        barPercentage: 0.5,
      },
    ];
    YAxisTitle = "Energy Consumed / Depleted";
  }

  console.log("dataset", dataset);
  // }
  // const [tooltipVisible, setTooltipVisible] = useState(false);
  // const [tooltipData, setTooltipData] = useState(null);
  // const [tooltipPos, setTooltipPos] = useState(null);

  // console.log("tooltipVisible:", tooltipVisible);
  // console.log("tooltipData:", tooltipData);
  // console.log("tooltipPos:", tooltipPos);

  const getFormat = () => {
    let f = "D/MM";
    return f;
  };

  const onClick = (event) => {
    // console.log(getDatasetAtEvent(chartRef.current, event));
    // console.log(getElementAtEvent(chartRef.current, event));
    const dataset = getDatasetAtEvent(chartRef.current, event);
    const element = getElementAtEvent(chartRef.current, event);
    const dataItem = dataset?.find((item) => item.index == element[0]?.index);
    console.log("dataItem:", dataItem);
    // console.log("dataItem.element.$context:", dataItem?.element?.$context);

    const chart = chartRef.current;

    if (chart.canvas) {
      // const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

      // // set position of tooltip
      // const left = positionX + 25;
      // const top = positionY

      if (dataItem?.element?.$context?.raw?.items?.length) {
        // setTooltipPos({ top: top, left: left });
        // setTooltipData(dataItem.element.$context);
        if (isTripChart) {
          if (sendEventToAndroid("eventTripStatsScreen")) {
            window.Android.eventTripStatsScreen(
              String(
                `https://${window.location.host}/TripStats?vtype=${vtype}&cid=${cid}`
              ),
              String("Trip Stats")
            );
          } else if (sendEventToiOS("eventTripStatsScreen")) {
            window.webkit.messageHandlers.eventTripStatsScreen.postMessage(
              String(
                `https://${window.location.host}/TripStats?vtype=${vtype}&cid=${cid}`
              ),
              String("Trip Stats")
            );
          }
          navigate(`/TripStats?vtype=${vtype}&cid=${cid}`, {
            state: {
              dataList: dataItem?.element?.$context?.raw?.items,
              isTripChart,
            },
          });
        } else {
          if (sendEventToAndroid("eventBatteryStatsScreen")) {
            window.Android.eventBatteryStatsScreen(
              String(
                `https://${window.location.host}/BatteryStats?vtype=${vtype}&cid=${cid}`
              ),
              String("Battery Stats")
            );
          } else if (sendEventToiOS("eventBatteryStatsScreen")) {
            window.webkit.messageHandlers.eventBatteryStatsScreen.postMessage(
              String(
                `https://${window.location.host}/BatteryStats?vtype=${vtype}&cid=${cid}`
              ),
              String("Battery Stats")
            );
          }
          navigate(`/BatteryStats?vtype=${vtype}&cid=${cid}`, {
            state: {
              dataList: dataItem?.element?.$context?.raw?.items,
              isTripChart,
            },
          });
        }
      }
    }
  };

  const options = {
    responsive: true,
    animation: false,
    layout: {
      padding: {
        right: 20,
      },
    },
    parsing: {
      xAxisKey: "date",
      // yAxisKey: "energy_consumption_per_day",
    },
    scales: {
      y: {
        // min: 0,
        // max:
        //   maxReading < positiveReadingMax
        //     ? positiveReadingMax + 1
        //     : getYAxisMax(),
        // offset: true,

        title: {
          display: true,
          text: YAxisTitle,
          font: {
            size: isTripChart ? 9 : 8,
          },
        },

        ticks: {
          // source: "data",
          // beginAtZero: true,
          callback(val) {
            return this.getLabelForValue(val.toFixed(1));
          },
          color: "#6b6a6d",
          padding: 0,
          font: {
            size: 9,
          },
        },
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
      },
      x: {
        type: "category",
        // reverse: true,
        // offset: readings.length === 1,
        title: {
          display: true,
          text: "Date",
          font: {
            size: 9,
          },
        },
        ticks: {
          // source: "labels",
          maxTicksLimit: 7,
          maxRotation: 0,
          minRotation: 0,
          // For a category axis, the val is the index so the lookup via getLabelForValue is needed
          callback(val) {
            const format = getFormat();
            // console.log("val:", this.getLabelForValue(val));
            // console.log("val:", moment(this.getLabelForValue(val)));

            // Hide the label of every 2nd dataset
            return moment(this.getLabelForValue(val)).format(format).split(",");
          },
          font: {
            size: 9,
          },
          padding: 0,
          color: "#6b6a6d",
        },
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      // legend: {
      //   position: 'top',
      // },
      // title: {
      //     display: true,
      //     text: 'Orko Line Chart',
      // },
      tooltip: {
        enabled: false,
        external: (context) =>
          externalTooltipHandlerBarChart(context, timeFilterValue),
      },
    },
    onHover: (event, chartElement) => {
      event.chart.canvas.style.cursor = chartElement[0] ? "pointer" : "default";
    },
  };
  const data = {
    // labels: readings.map(o => o.timestamp),
    datasets: dataset,
  };

  // const closeTooltip = (event) => {
  //     setTooltipPos(null);
  //     setTooltipData(null);
  // }

  return (
    <div className="graph-container">
      <Bar
        options={options}
        data={data}
        ref={chartRef}
        onClick={onClick}
        datasetIdKey="date"
        redraw={true}
      />
      {/* {tooltipPos ? <GraphModal tooltipData={tooltipData} tooltipPos={tooltipPos} onClose={closeTooltip} /> : ""} */}
    </div>
  );
}
export default BarGraph;
