import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAlert: false,
  alert: {},
};

export const alertsSlice = createSlice({
  name: "alerts",
  initialState,
  reducers: {
    showMsg: (state, payloadData) => {
      const { payload } = payloadData;
      state.alert = { ...payload };
      state.isAlert = true;
    },
    resetAlert: (state) => {
      state.isAlert = false;
      state.alert = {};
    },
  },
});

// Action creators are generated for each case reducer function
export const { showMsg, resetAlert } = alertsSlice.actions;

export default alertsSlice.reducer;
