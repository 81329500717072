import moment from "moment";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
} from "chart.js";
import { externalTooltipHandler } from "../../helper/GraphHelpers";
import StaticGraph from "../StaticGraph/StaticGraph";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement);

const LineGraph = ({ readings, backgroundColor, loading }) => {
  const getFormat = () => {
    let f = "h:mmA";

    return f;
  };

  if (!readings?.length) return <StaticGraph loading={loading} />;

  const options = {
    responsive: true,
    animation: false,
    layout: {
      padding: {
        right: 20,
      },
    },
    parsing: {
      xAxisKey: "date_time_gmt_05",
      yAxisKey: "soc",
    },
    scales: {
      y: {
        // min: 0,
        // max:
        //   maxReading < positiveReadingMax
        //     ? positiveReadingMax + 1
        //     : getYAxisMax(),
        offset: true,

        title: {
          display: true,
          text: "SOC",
          font: {
            size: 9,
          },
          padding: {
            bottom: 0,
          },
        },

        ticks: {
          // source: "data",
          // beginAtZero: true,
          callback(val) {
            return this.getLabelForValue(val.toFixed(1));
          },
          font: {
            size: 9,
          },
          padding: 5,
          color: "#6b6a6d",
        },
        grid: {
          drawTicks: false,
        },
      },
      x: {
        type: "category",
        // reverse: true,
        // offset: readings.length === 1,
        ticks: {
          // source: "labels",
          maxTicksLimit: 6,
          maxRotation: 0,
          minRotation: 0,
          // For a category axis, the val is the index so the lookup via getLabelForValue is needed
          callback(val) {
            const format = getFormat();
            // console.log("val:", this.getLabelForValue(val));
            // console.log("val:", moment(this.getLabelForValue(val)));
            const len = readings.length - 1;
            const pointsToTakeOut = 4; //+2   1 for first and 1 for last // so it 6
            // Calculate the step size to take out points at equal positions
            const stepSize = Math.floor(len / (pointsToTakeOut + 1));
            // Initialize an array to store the result
            const resultArray = [];
            // Add the first element (1) to the result array
            resultArray.push(0);
            // Iterate to add the points at equal intervals
            for (let i = 1; i <= pointsToTakeOut; i++) {
              const nextPoint = i * stepSize;
              resultArray.push(nextPoint);
            }
            // Add the last element (100) to the result array
            resultArray.push(len);
            if (resultArray.includes(val)) {
              // Hide the label of every 2nd dataset
              return moment(this.getLabelForValue(val))
                .format(format)
                .split(",");
            }
          },
          font: {
            size: 9,
          },
          color: "#6b6a6d",
        },
        title: {
          display: true,
          text: "Time",
          font: {
            size: 9,
          },
          padding: {
            top: 0,
          },
        },
        // grid: {
        //     display: false,
        // },
      },
    },
    plugins: {
      // legend: {
      //   position: 'top',
      // },
      // title: {
      //     display: true,
      //     text: 'Orko Line Chart',
      // },
      tooltip: {
        enabled: false,
        external: externalTooltipHandler,
      },
    },
    onHover: (event, chartElement) => {
      event.chart.canvas.style.cursor = chartElement[0] ? "pointer" : "default";
    },
  };
  const data = {
    // labels: readings.map(o => o.timestamp),
    datasets: [
      {
        type: "line",
        data: readings,
        fill: false,
        borderColor: backgroundColor,
        backgroundColor: "grey",
        // borderWidth: 3,
        // tension: 0.5,
        // pointBackgroundColor: colorRange,
        pointBorderColor: backgroundColor,
        pointBorderWidth: 0.5,
        // hoverBorderColor: variables.colorRange,
        pointRadius: 2,
        // pointHoverRadius: 5
        pointHitRadius: 5,
      },
    ],
  };

  return (
    <div className="line-graph-container">
      <Line options={options} data={data} datasetIdKey="date_time_gmt_05" />
    </div>
  );
};

export default LineGraph;
