import { useSelector, useDispatch } from "react-redux";
import { resetAlert } from "../../store/slices/alertsSlice";
import { Icons } from "../../assets/icons/index";

import "./index.scss";
function Alerts() {
  const { alert, isAlert } = useSelector((state) => state.alertsSlice);
  const dispatch = useDispatch();

  if (alert?.type && alert?.msg) {
    setTimeout(() => {
      alertClose();
    }, 3000);
  }

  function alertClose() {
    dispatch(resetAlert());
  }

  return (
    <div
      className={`Alert-Window succes ${alert?.type || "primary"} ${
        isAlert ? "show" : "close"
      }`}
    >
      <div className="d-flex align-center">
        <div className="alert-icon">
          <img src={Icons["warning"]} width={"20px"} alt="missing graphics" />
        </div>
        <div className="alert-content">{alert.msg}</div>
      </div>
      <div className="alert-action" onClick={alertClose}>
        X
      </div>
    </div>
  );
}
export default Alerts;
