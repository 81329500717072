import { useEffect } from 'react';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';

interface IBatteryStatsProps {
}

interface IDataItem {
    start_datetime: any;
    end_datetime: any;
    start_soc: any;
    end_soc: any;
    duration: any;
    charge_gain: any;
    energy_consumption: any;
    monetary_cost: any;
    saving: any;
}

const BatteryStats: React.FunctionComponent<IBatteryStatsProps> = (props) => {
    const navigate = useNavigate();
    const { state, search } = useLocation();
    const { dataList, isTripChart } = state;

    // console.log("state:", search);
    useEffect(() => {
        if (!dataList.length) {
            if (isTripChart) {
                navigate(`/TotalDistance${search}`)
            } else {
                navigate(`/BatteryManagement${search}`)
            }
        }
    }, [])

    return (
        <div className="container_wrapper container_wrapper_battery">
            <div className='battery-stats'>
                {renderTitle(isTripChart)}
                <div className='data-list'>
                    {dataList.map((item: IDataItem, index: number) => {
                        return renderDataItem(item, index, dataList, isTripChart);
                    })}
                </div>
            </div>
        </div>
    );
};

const renderTitle = (isTripChart: boolean) => {
    return (
        isTripChart ? <h3>Trips</h3> : <h3>Charging Sessions</h3>
    )
}


const renderDataItem = (item: any, key: number, arr: any, isTripChart: string) => {
    return (
        <div className='data-item' key={key}>
            <span>{isTripChart ? "Trip" : "Session"} {key + 1}:</span>
            {isTripChart ? renderTripsStats(item) : renderChargingStats(item)}
            {arr[key + 1] ? <hr></hr> : ''}
        </div>
    )
}

const renderText = (key: string, value: string) => {
    return (
        <span><b>{key}</b> {value}</span>
    )
}

const renderChargingStats = (item: IDataItem) => (
    <>
        {renderText("Start Date Time:", moment(item?.start_datetime.slice(0, -1)).format("hh:mm A, DD/MM/YYYY") || "N/A")}
        {renderText("End Date Time:", moment(item?.end_datetime.slice(0, -1)).format("hh:mm A, DD/MM/YYYY") || "N/A")}
        {renderText("Start SOC:", `${item.start_soc || 0}%`)}
        {renderText("End SOC:", `${item.end_soc || 0}%`)}
        {renderText("Duration:", `${item.duration || 0}m`)}
        {renderText("Charge Gain:", `${item.charge_gain || 0}%`)}
        {renderText("Energy Consumption:", `${item.energy_consumption || 0}`)}
        {renderText("Monetary Cost:", `${item.monetary_cost?.toFixed(2) || 0} PKR`)}
        {renderText("Savings:", `${item.saving?.toFixed(2) || 0} PKR`)}
    </>
)

const renderTripsStats = (item: any) => (
    <>
        {renderText("Start Date Time:", moment(item?.start_datetime.slice(0, -1)).format("hh:mm A, DD/MM/YYYY") || "N/A")}
        {renderText("End Date Time:", moment(item?.end_datetime.slice(0, -1)).format("hh:mm A, DD/MM/YYYY") || "N/A")}
        {renderText("Start SOC:", `${item.start_soc || 0}%`)}
        {renderText("End SOC:", `${item.end_soc || 0}%`)}
        {renderText("Duration:", `${item.duration || 0}m`)}
        {renderText("Distance:", `${item.distance || 0}km`)}
        {renderText("Battery Consumed:", `${item.battery_consumed || 0}%`)}
        {renderText("Monetary Cost:", `${item.monetary_cost?.toFixed(2) || 0} PKR`)}
        {renderText("Savings:", `${item.saving?.toFixed(2) || 0} PKR`)}
    </>
)

export default BatteryStats;
