

export class LocalDB {

    static get(key: string) {
        return localStorage.getItem(key)
    }

    static set(key: string, value: string) {
        localStorage.setItem(key, value);
    }

    static remove(key: string) {
        localStorage.removeItem(key);     
    }
    
    static getMany(keys: string[]) {
        let data = []
        for (const key of keys) {
            data.push(this.get(key));
        }
        return data;
    }
    
    static setMany(entries: {key: string, value: string}[]) {
        for (const {key, value} of entries) {
            this.set(key, value);
        }
    }
    
    static removeMany(keys: string[]) {
        for (const key of keys) {
           this.remove(key);
        }
    }

    static clear() {
        localStorage.clear();
    }

}