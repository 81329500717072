import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  Vehicle,
  ChargingStation,
  BatteryManagement,
  NotFound,
  Emission,
  JobList,
  JobDetail,
  JobRoute,
  Crash,
  CrashScreen,
  UnexpectedCrashScreen,
  TotalDistance,
} from "./modules";
// import Pusher from "pusher-js";

import { useSelector } from "react-redux";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import {
  withAITracking,
  ReactPlugin,
} from "@microsoft/applicationinsights-react-js";
import { SeverityLevel } from "@microsoft/applicationinsights-common";
import { useEffect, useRef, useState } from "react";
import { PusherActions } from "./helper/Pusher/PusherActions";
import { usePusher } from "./helper/Pusher/usePusher";
import { connectPusher } from "./api/Pusher";
import { getAddressFromLatLng } from "./api/Address";
import BatteryStats from "./modules/BatteryStats";
import { GlobalEvents } from "./Utilities/GlobalEvents";
import { isDev } from "./helper";

var reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_AZURE_INSIGHTS,
    enableAutoRouteTracking: true,
    extensions: [reactPlugin],
  },
});
appInsights.loadAppInsights();
let pages = [
  {
    path: "",
    page: ({ address, data }) => <Vehicle address={address} data={data} />,
  },
  {
    path: "/ChargingStation",
    page: ({ address, data }) => (
      <ChargingStation address={address} data={data} />
    ),
  },
  {
    path: "/BatteryManagement",
    page: ({ address, data }) => (
      <BatteryManagement address={address} data={data} />
    ),
  },
  {
    path: "/BatteryStats",
    page: ({ address, data }) => <BatteryStats address={address} data={data} />,
  },
  {
    path: "/Emission",
    page: ({ address, data }) => <Emission address={address} data={data} />,
  },
  {
    path: "/JobList",
    page: ({ address, data }) => <JobList address={address} data={data} />,
  },
  {
    path: "/JobDetail",
    page: ({ address, data }) => <JobDetail address={address} data={data} />,
  },
  {
    path: "/JobRoute",
    page: ({ address, data }) => <JobRoute address={address} data={data} />,
  },
  {
    path: "/TotalDistance",
    page: ({ address, data }) => (
      <TotalDistance address={address} data={data} />
    ),
  },
  {
    path: "/TripStats",
    page: ({ address, data }) => <BatteryStats address={address} data={data} />,
  },
  {
    path: "/Crash",
    page: () => <Crash />,
  },
  {
    path: "*",
    page: () => <NotFound />,
  },
];

function App() {
  // const [address, setAddress] = useState("");
  // const { data, address } = usePusher();
  // const state = useSelector((state) => state.pusherSlice);
  // const { pusher: data, address } = state;
  const address = "";
  const data = {};
  // const data = state.pusher

  const [pusher, setPusher] = useState(null);
  const queryParams = new URLSearchParams(window.location.search);
  const cid = queryParams.get("cid");
  const app = queryParams.get("app");
  const domain = queryParams.get("domain");
  const errors = useRef([]);

  console.log("data AAA:", data);
  // console.log("state= SSS:", state);

  useEffect(() => {
    PusherActions.init();
  }, []);

  // const [pusher, setPusher] = useState(null)
  // useEffect(() =>
  // {
  //   const queryParams = new URLSearchParams(window.location.search);

  //   const domain = queryParams.get("domain");

  //   const connectPusher = new Pusher(process.env.REACT_APP_PUSHER_ENV, {
  //     cluster: "ap2",
  //     authEndpoint: `${process.env.REACT_APP_SERVER}/api/v1/cvp/pusher/auth`,
  //     auth: {
  //       headers: {
  //         "Domain": domain
  //       }
  //     }

  //   });
  //   if(connectPusher)
  //   setPusher(connectPusher)
  // }, [])

  //  useEffect(() => {
  //   window.addEventListener("error", (event) => {
  //     setErrors(errors=>[...errors, event]);
  //     return false;
  //   });

  //   window.addEventListener("unhandledrejection", (event) => {
  //     setErrors(errors=>[...errors, event]);
  //     return false;
  //   });
  // }, []);
  const sendErrorAppInsights = (errors) => {
    console.log("appInsights", appInsights);
    console.log("errors", errors);
    if (errors?.length) {
      if (isDev()) return;
      appInsights.trackException(
        {
          error: { "Map Error": errors },
          exception: { "Map Error": errors },
          severityLevel: SeverityLevel.Error,
          properties: { "Map Error": errors },
        },
        { module: "React Application", vehicle: { cid: cid } }
      );
    }
  };

  useEffect(() => {
    let timeout = null;
    console.defaultError = console.error.bind(console);
    // errors.current = [];
    console.error = function () {
      // default &  console.error()
      console.defaultError.apply(console, arguments);
      // new & array data
      errors.current.push(Array.from(arguments));
    };
    GlobalEvents.listenToEvent("SendError", () => {
      console.log("Errors", data);
      timeout = setTimeout(() => sendErrorAppInsights(errors.current), 5000);
    });
    return () => clearTimeout(timeout);
  }, []);

  const getDataFromMobile = (data) => {
    let pusher = {};

    if (data?.detail?.pusher) {
      pusher = JSON.parse(data?.detail?.pusher);
    }

    // if (data?.detail?.address) {
    //   setAddress(data?.detail?.address ?? "")
    // }
    console.log("Boolean(data?.detail?.pusher)", Boolean(data?.detail?.pusher));
    if (Boolean(data?.detail?.pusher)) {
      console.log("this called");
      pusher = {
        ...pusher,
        latitude: pusher?.latitude ? Number(pusher.latitude) : null,
        longitude: pusher?.longitude ? Number(pusher.longitude) : null,
      };
      PusherActions.setPusherData({
        pusher,
        address: data?.detail?.address ?? "",
      });
    }
  };
  useEffect(() => {
    window.addEventListener("onChangedPusher", getDataFromMobile);

    return () => {
      window.removeEventListener("onChangedPusher", getDataFromMobile);
    };
  }, []);

  useEffect(() => {
    if (!Object.keys(data || {}).length) {
      PusherActions.getPusherDataFormLocalDB(cid);
    }
  }, []);

  useEffect(() => {
    if (app === "web") {
      subscribeChannel();
    }
    return () => {
      if (pusher) pusher.unsubscribe(cid);
    };
  }, []);

  const updateAddress = async (data) => {
    const { latitude, longitude } = data;
    let address = "";
    if (latitude && longitude) {
      try {
        const res = await getAddressFromLatLng({ latitude, longitude });
        console.log("res address:", res);
        const { display_name } = res.data;
        address = display_name;
        // PusherActions.setPusherData({ pusher: data, address: display_name });
      } catch (error) {
        console.log(error);
      }
    }
    return address;
  };

  const subscribeChannel = async () => {
    const pusher = await connectPusher(domain);
    console.log("CALLLED");
    setPusher(pusher);
    if (pusher) {
      const channel1 = pusher.subscribe(`private-channel-${cid}`);

      // private channel id
      channel1.bind(
        "vehicle-telematic-data-update",
        async function (pusherData) {
          console.log("pusherData:", pusherData);
          if (pusherData) {
            const pusher = {
              ...pusherData,
              latitude: pusherData?.latitude
                ? Number(pusherData.latitude)
                : null,
              longitude: pusherData?.longitude
                ? Number(pusherData.longitude)
                : null,
            };
            const address = await updateAddress(pusher);
            PusherActions.setPusherData({ pusher, address });
          }
        }
      );
    }
  };

  // const addEvent = (name, payload) => {
  //   let event = new CustomEvent(name, payload);
  //   let eventData = { name, event };
  //   // this.events.push(eventData);
  //   return eventData;
  // };

  // const dispatchEvent = (name, payload) => {
  //   let { event } = addEvent(name, { detail: payload });

  //   console.log("event", event);
  //   if (event) {
  //     window.dispatchEvent(event)
  //   }
  // }

  // useEffect(() => {
  //   dispatchEvent("onChangedPusher", { address: "abc.adress", pusher: `{"_id":"647dcdb04088c893a8c706cc","domain":"Vlektra","latitude":24.815583,"longitude":67.022093,"realtime_location":true,"ident":"350612070215551","soc":83,"state_of_health":"","remaining_capacity":"","full_capacity":"","remaining_distance":"74.7","fuel_economy":"0.63","vehicle_status":"Normal","batteries":{},"timestamp":"2023-06-05 11:57:40","daily_distance":0,"total_distance_travelled":"85","emission_saving":"0.70","cumulative_emission_saving":"1.88","tree_saved":"2","total_fuel_cost":947.32,"total_electric_cost":67.49,"total_fuel_savings":879.83}` })
  //   // dispatchEvent("onChangedPusher", { address: "abc.adress", pusher: "{\"mid\": \"648959ad64e2ffa98437b8e2\", \"ident\": \"350612070218373\", \"domain\": \"Vlektra\", \"is_bms_check_passed\": true, \"is_ecu_check_passed\": true, \"is_iot_check_passed\": true, \"remaining_capacity\": \"\", \"full_capacity\": \"\", \"soc\": \"78\", \"latitude\": \"24.815403\", \"longitude\": \"67.02204\", \"fuel_economy\": \"0.63\", \"vehicle_status\": \"Normal\", \"timestamp\": \"2023-06-14 06:09:50\", \"daily_distance\": \"11.42\", \"total_distance_travelled\": \"605.61\", \"emission_saving\": \"48.46\", \"cumulative_emission_saving\": \"48.46\", \"remaining_distance\": \"70.20\", \"tree_saved\": \"60.57\", \"total_fuel_cost\": \"5666.78\", \"total_electric_cost\": \"403.74\"}" })
  // }, [])

  const ComponentWithTracking = withAITracking(appInsights, () => (
    <BrowserRouter>
      {/* <Fragment> */}
      {/* <Provider store={store}> */}
      <Routes>
        {pages.map((values, index) => {
          return (
            <Route
              key={"route" + index}
              exact
              path={values?.path}
              element={values.page({ address, data })}
            />
          );
        })}
      </Routes>
      {/* </Provider> */}
      {/* </Fragment> */}
    </BrowserRouter>
  ));
  return (
    <UnexpectedCrashScreen
      onError={() => <CrashScreen />}
      appInsights={reactPlugin}
    >
      <ComponentWithTracking />
    </UnexpectedCrashScreen>
  );
}

export default App;
