type CustomEvent = { name: string, event: Event };

export class GlobalEvents {

    static events: CustomEvent[] = []

    static addEvent(name: string, payload: any) : CustomEvent {
        let event = new CustomEvent(name, payload);
        let eventData = { name, event };
        this.events.push(eventData);
        return eventData;
    };

    static listenToEvent(name: string, callback: Function) {

        window.addEventListener(name, (event: any) => {
            callback(event.detail);
        });

    }

    static dispatchEvent(name: string, payload: any) {
        let {event} = this.addEvent(name, {detail: {payload}});

        if(event) {
            window.dispatchEvent(event)
        }
    }

}