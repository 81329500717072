import { Icons } from "../assets/icons";

export interface ICrashScreenProps {
}

function CrashScreen(props: ICrashScreenProps) {
    return (
        <div className='crash-screen'>
            <h2>OPPS!</h2>
            <img src={Icons.crashScreen} alt="crashScreen" />
            <h3>Someting went wrong <br /> please try again. </h3>
            <button>Try Again</button>
        </div>
    );
}

export default CrashScreen;
