/*global google*/
import React, { useEffect, useState } from "react";
import axios from "axios";
import GoogleMapStyle from "../helper/googleMap.json";
import { sendEventToAndroid, sendEventToiOS } from "../helper";
import { Icons } from "../assets/icons";
import {
  GoogleMap,
  Marker,
  Polyline,
  withScriptjs,
  withGoogleMap,
} from "react-google-maps";
import { compose, withProps } from "recompose";

const GoogleMapComponent = compose(
  withProps((props) => ({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_KEY}`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100vh` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  })),
  withScriptjs,
  withGoogleMap
)((props) => {
  useEffect(() => {
    if (props.autoPlay && props.polylines.length > 0) {
      handleStartTrip();
    }
  }, [props.polylines]);

  const handleStartTrip = () => {
    if (props.markers.length > 0) {
      const startLocation = props.markers.find(
        (marker) => marker.primary_key === "start"
      );
      const endLocation = props.markers.find(
        (marker) => marker.primary_key === "end"
      );
      if (startLocation && endLocation) {
        // console.log("here", props.polylines[0]?.path);
        // const { latitude: startLat, longitude: startLng } = startLocation;
        // const { latitude: endLat, longitude: endLng } = endLocation;

        if (sendEventToAndroid("eventGoogleMap")) {
          window.Android.eventGoogleMap(
            String(JSON.stringify(props.polylines[0]?.path)),
            String("Google Map")
          );
        } else if (sendEventToiOS("eventGoogleMap")) {
          window.webkit.messageHandlers.eventGoogleMap.postMessage(
            String(JSON.stringify(props.polylines[0]?.path)),
            String("Google Map")
          );
        }

        // Construct the Google Maps URL
        // const url = `https://www.google.com/maps/dir/?api=1&origin=${startLat},${startLng}&destination=${endLat},${endLng}&travelmode=driving`;
        // Open Google Maps in a new tab or app
        // window.open(url, "_blank");
      }
    }
  };

  return (
    <div style={{ height: "100%" }}>
      <GoogleMap
        ref={(map) => props.setMap(map)}
        defaultZoom={props.options.zoom}
        zoom={props.options.zoom}
        defaultCenter={props.options.center}
        center={props.options.center}
        defaultOptions={{
          styles: GoogleMapStyle,
          mapTypeControl: false,
          streetViewControl: false,
        }}
      >
        {props.locations &&
          props.locations.map((marker, i) => (
            <Marker
              key={i}
              position={{
                lat: parseFloat(marker.latitude),
                lng: parseFloat(marker.longitude),
              }}
              icon={
                marker.src && {
                  url: marker.src,
                  scaledSize: new window.google.maps.Size(40, 50),
                }
              }
            />
          ))}
        {props.polylines &&
          props.polylines.map((polyline, i) => (
            <Polyline
              key={i}
              path={polyline.path}
              options={{
                strokeColor: polyline.color || "#FF0000",
                strokeOpacity: 1.0,
                strokeWeight: 2,
                icons: [
                  {
                    icon: " ",
                    offset: "100%",
                    repeat: "5px",
                  },
                ],
              }}
            />
          ))}
      </GoogleMap>
      <button
        onClick={handleStartTrip}
        style={{
          position: "absolute",
          top: "10px",
          left: "10px",
          padding: "10px 20px",
          backgroundColor: "rgb(255, 255, 255)",
          boxShadow: "rgba(0, 0, 0, 0.3) 0px 1px 4px -1px",
          color: "#000",
          fontWeight: 600,
          border: "none",
          cursor: "pointer",
          zIndex: 9999,
        }}
      >
        Start Trip
      </button>
    </div>
  );
});

const Map = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("token");
  const job_id = parseInt(queryParams.get("job_id"));
  const autoPlay = queryParams.get("autoplay") === "true" ? true : false;
  const [loader, setLoader] = useState(false);
  const [jobDetail, setJobDetail] = useState({});

  const [map, setMap] = useState(null);
  const [userPosition, setUserPosition] = useState({
    lat: 24.8607,
    lng: 67.0011,
  });
  const [markers, setMarkers] = useState([]);
  const [route, setRoute] = useState([]);
  const [zoom, setZoom] = useState(15);

  function fetchData() {
    const headers = {
      Authorization: `Bearer ${token}`,
      Domain: "Fleet",
    };
    const url = `${process.env.REACT_APP_SERVER}/api/v1/routes/vehicle-job-detail/${job_id}`;
    setLoader(true);
    axios
      .get(url, { headers })
      .then((res) => {
        setJobDetail(res.data.body);
      })
      .finally(() => setLoader(false));
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (jobDetail?.route) {
      const len = jobDetail.route?.coordinates.length - 1;
      const Markers = [
        {
          primary_key: "start",
          latitude: jobDetail.route?.coordinates[0][1],
          longitude: jobDetail.route?.coordinates[0][0],
          src: Icons.startLocation, // Replace with your icon
        },
        {
          primary_key: "end",
          latitude: jobDetail.route?.coordinates[len][1],
          longitude: jobDetail.route?.coordinates[len][0],
          src: Icons.endLocation, // Replace with your icon
        },
      ];
      // for Markers
      setMarkers(Markers);
      // for Polyline
      const path = jobDetail.route?.coordinates.map((r) => ({
        lat: r[1],
        lng: r[0],
      }));
      setRoute([{ path, color: "#20a390 " }]);
    }
  }, [jobDetail]);

  useEffect(() => {
    if (map && markers.length) {
      const bounds = new window.google.maps.LatLngBounds();
      markers.forEach((marker) => {
        if (marker.latitude && marker.longitude) {
          bounds.extend(
            new window.google.maps.LatLng(
              parseFloat(marker.latitude),
              parseFloat(marker.longitude)
            )
          );
        }
      });
      map.fitBounds(bounds);
    }
  }, [map, markers]);

  return (
    <div style={{ height: "100vh", width: "100%" }}>
      <GoogleMapComponent
        id="myMap"
        options={{
          center: userPosition,
          zoom: zoom,
        }}
        autoPlay={autoPlay}
        locations={markers}
        polylines={route}
        setMap={(a) => setMap(a)}
        markers={markers} // Pass markers to the component
      />
    </div>
  );
};

export default Map;
