import moment from "moment";
import { TDifferenceIn, TimeFilterValue } from "../Entities/Interfaces/IGraphs";

export const getBatteryReadings = (readings: any[]) => {
    // if (!readings?.length) return [];

    const groupedReadings = getGroupedReadingsByDate(readings);

    // console.log("groupedReadings:", groupedReadings);

    // let totalSpent = 0;
    // let totalCharged = 0;
    // let totalSaving = 0;

    const sumedGroupReading = groupedReadings.map((groupReading) => {
        // console.log("groupReading:", groupReading);
        const totalPerDay = groupReading.items.reduce((accumulator: { charge_gain_per_day: any; monetary_cost_per_day: any; energy_consumption_per_day: any; saving_per_day: any }, item: { charge_gain: any; monetary_cost: any; energy_consumption: any; saving: any }) => {
            accumulator.charge_gain_per_day += item.charge_gain;
            accumulator.monetary_cost_per_day += item.monetary_cost;
            accumulator.energy_consumption_per_day += item.energy_consumption;
            accumulator.saving_per_day += item.saving;
            return accumulator;
        }, {
            charge_gain_per_day: 0,
            monetary_cost_per_day: 0,
            energy_consumption_per_day: 0,
            saving_per_day: 0
        });
        // console.log("newArr:", totalPerDay);
        // totalSpent += totalPerDay.monetary_cost_per_day;
        // totalCharged += totalPerDay.energy_consumption_per_day;
        // totalSaving += totalPerDay.saving_per_day;

        if (!totalPerDay.charge_gain_per_day) {
            totalPerDay.charge_gain_per_day = null
        }
        if (!totalPerDay.energy_consumption_per_day) {
            totalPerDay.energy_consumption_per_day = null
        }

        return {
            ...groupReading,
            ...totalPerDay,
        }
    });
    // console.log("sumedGroupReading:", sumedGroupReading);
    // console.log("totalSpent:", totalSpent);
    // console.log("totalCharged:", totalCharged);

    return sumedGroupReading;
}

export const getGroupedReadingsByDate = (readings: any[]) => {
    const previousDaysObj = getPreviousDaysObject();

    const groups = readings.reduce((groups, item) => {
        // console.log("groups",groups);
        const date = item.start_datetime.split('T')[0];
        // const date = moment(item.start_datetime).format("YYYY-MM-DD");

        if (!groups[date]) {
            groups[date] = [];
        }
        groups[date].push(item);
        return groups;
    }, previousDaysObj);

    // console.log("groups",groups);

    // Edit: to add it in the array format instead
    const groupArrays = Object.keys(groups).map((date) => {
        return {
            date,
            items: groups[date]
        };
    });
    return groupArrays;
};

const getPreviousDaysObject = () => {
    const obj: any = {}
    const today = moment();
    const res = Array(7).fill("").map(
        () => obj[today.subtract(1, 'd').format('YYYY-MM-DD')] = []
    );
    // obj[moment().format('YYYY-MM-DD')] = [];
    // res.unshift(moment().format('YYYY-MM-DD'))
    // console.log("res:", res);
    // console.log("obj:", obj);
    return obj;
}

export const getTimeDifferenceFromNow = (
    timeStamp: number,
    differenceIn: TDifferenceIn
) => {
    const currentDate = new Date();
    const itemDate = timeStamp;

    const diff = moment(currentDate).diff(itemDate, differenceIn, true);
    return diff;
};

export const getFilteredReading = (readings: any[], difference: number, differenceIn?: any) => {
    if (!readings?.length) return {
        total_spent: 0,
        total_charged: 0,
        total_saving: 0,
        total_energy_consumption: 0,
        readings: []
    };
    let diffIn: TDifferenceIn = "days";

    if (differenceIn) {
        diffIn = differenceIn;
    }
    // console.log("differenceIn=> ", differenceIn);

    let filterdArr = readings;

    if (difference) {
        filterdArr = readings.filter((item: { [x: string]: any; }) => {
            const diff = getTimeDifferenceFromNow(item.date, diffIn);

            // if (differenceIn) {
            // console.log("diff=> ", item.date, diff);
            // }

            if (diff <= difference) {
                return true;
            }
            return false;
        });
    }

    console.log("filterdArr:", filterdArr);


    let totalSpent = 0;
    let totalCharged = 0;
    let totalSaving = 0;
    let totalEnergyConsumption = 0;

    const finalReadings = filterdArr.map((item: any) => {
        totalSpent += item.monetary_cost_per_day;
        totalCharged += item.charge_gain_per_day;
        totalSaving += item.saving_per_day;
        totalEnergyConsumption += item.energy_consumption_per_day;
        return {
            ...item,
            timestamp: moment(item.date).format('X'),
        };
    });

    const sortedArr = finalReadings.sort((x: { [x: string]: number; }, y: { [x: string]: number; }) => {
        return y.timestamp - x.timestamp;
    });


    return {
        total_spent: totalSpent,
        total_charged: totalCharged,
        total_saving: totalSaving,
        total_energy_consumption: totalEnergyConsumption,
        readings: sortedArr
    };
};

export const getFilteredReadingHandler = (
    key: any,
    allReadings: any[],
) => {
    let readings: any = {
        total_spent: 0,
        total_charged: 0,
        total_saving: 0,
        readings: []
    };;

    if (key === 0) {
        readings = getFilteredReading(allReadings, 8);
    } else if (key === 1) {
        readings = getFilteredReading(allReadings, moment().daysInMonth());
    }
    return readings;
};

export const getEnergyDepletedReadings = (readings: any[], difference: number, differenceIn?: any) => {
    const previousDaysObj = getPreviousDaysObject();

    const groups = readings.reduce((groups, item) => {
        // console.log("groups",groups);
        // const date = item.start_datetime.split('T')[0];
        const date = moment(item.date).format("YYYY-MM-DD");

        if (!groups[date]) {
            groups[date] = [];
        }
        groups[date].push(item);
        return groups;
    }, previousDaysObj);

    const groupArrays = Object.entries(groups).map((item: any) => {
        if (!item[1].length) {
            return { date: item[0], energy_consumption: null }
        }
        return item[1][0];
    });
    // console.log("previousDaysObj:", previousDaysObj);
    // console.log("groups::", groups);
    // console.log("groupArrays::", groupArrays);

    // console.log("readings::", readings);

    // if (!readings?.length) return [];

    let diffIn: TDifferenceIn = "days";

    if (differenceIn) {
        diffIn = differenceIn;
    }
    // console.log("differenceIn=> ", differenceIn);

    let filterdArr = groupArrays;

    if (difference) {
        filterdArr = groupArrays.filter((item: { [x: string]: any; }) => {
            const diff = getTimeDifferenceFromNow(item.date, diffIn);

            // if (differenceIn) {
            // console.log("diff=> ", item.date, diff);
            // }

            if (diff <= difference) {
                return true;
            }
            return false;
        });
    }

    // console.log("filterdArr:", filterdArr);

    const finalReadings = filterdArr.map((item: any) => {

        return {
            ...item,
            energy_consumption: item.energy_consumption || null,
            timestamp: moment(item.date).format('X'),
        };
    });

    const sortedArr = finalReadings.sort((x: { [x: string]: number; }, y: { [x: string]: number; }) => {
        return y.timestamp - x.timestamp;
    });

    return sortedArr;

}

export const getTripsReadings = (readings: any[], difference: number, differenceIn?: any) => {
    // if (!readings?.length) return [];

    const groupedReadings = getGroupedReadingsByDate(readings);

    // console.log("groupedReadings:",groupedReadings);

    const sumedGroupReading = groupedReadings.map((groupReading) => {
        // console.log("groupReading:", groupReading);
        
        const totalPerDay = groupReading.items.reduce((accumulator: { distance_per_day: any; }, item: { distance: any; }) => {
            accumulator.distance_per_day += item.distance;
            return accumulator;
        }, {
            distance_per_day: 0,
  
        });

        if (!totalPerDay.distance_per_day) {
            totalPerDay.distance_per_day = null
        }

        return {
            ...groupReading,
            ...totalPerDay,
        }
    });
    // console.log("sumedGroupReading:", sumedGroupReading);
    // console.log("totalSpent:", totalSpent);
    // console.log("totalCharged:", totalCharged);

    // return sumedGroupReading;
    let diffIn: TDifferenceIn = "days";

    if (differenceIn) {
        diffIn = differenceIn;
    }
    // console.log("differenceIn=> ", differenceIn);

    let filterdArr = sumedGroupReading;

    if (difference) {
        filterdArr = sumedGroupReading.filter((item: { [x: string]: any; }) => {
            const diff = getTimeDifferenceFromNow(item.date, diffIn);

            // if (differenceIn) {
            // console.log("diff=> ", item.date, diff);
            // }

            if (diff <= difference) {
                return true;
            }
            return false;
        });
    }

    // console.log("filterdArr:", filterdArr);

    const finalReadings = filterdArr.map((item: any) => {
        return {
            ...item,
            timestamp: moment(item.date).format('X'),
        };
    });

    const sortedArr = finalReadings.sort((x: { [x: string]: number; }, y: { [x: string]: number; }) => {
        return y.timestamp - x.timestamp;
    });

    return sortedArr;
}

const getOrCreateTooltip = (chart: { canvas: { parentNode: { querySelector: (arg0: string) => any; appendChild: (arg0: any) => void; }; }; }, className?: string) => {
    let tooltipEl = chart.canvas.parentNode.querySelector("div");

    if (!tooltipEl) {
        tooltipEl = document.createElement("div");
        tooltipEl.style.background = "white";
        tooltipEl.style.borderRadius = "3px";
        tooltipEl.style.color = "white";
        tooltipEl.style.opacity = 1;
        tooltipEl.style.pointerEvents = "none";
        tooltipEl.style.position = "absolute";
        tooltipEl.style.minWidth = "90px";
        tooltipEl.style.transform = "translate(-50%, 0)";
        tooltipEl.style.transition = "all .1s ease";
        tooltipEl.classList.add("tooltip-v1");
        if (className) {
            tooltipEl.classList.add(className);
        }

        const table = document.createElement("table");
        table.style.margin = "0px";

        tooltipEl.appendChild(table);
        chart.canvas.parentNode.appendChild(tooltipEl);
    }

    return tooltipEl;
};


export const externalTooltipHandler = (context: { chart: any; tooltip: any; }) => {
    // Tooltip Element
    const { chart, tooltip } = context;
    if (!tooltip?.dataPoints?.length) return;
    const toolTipData = tooltip?.dataPoints[0]?.raw;
    const tooltipEl = getOrCreateTooltip(chart);

    // console.log(tooltip);
    // console.log(tooltipEl);
    // console.log(toolTipData);
    // console.log(chart);

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
    }

    // Set Text
    //   if (tooltip.body) {

    const tableHead = document.createElement("thead");

    const trHead = document.createElement("tr");

    trHead.style.borderWidth = "0px";

    const readingElement = document.createElement("span");
    const B = document.createElement("b")
    B.textContent = "SOC: "
    readingElement.appendChild(B);
    readingElement.style.color = "grey";

    const th = document.createElement("th");
    th.style.borderWidth = "0px";
    th.style.display = "flex";
    th.style.alignItems = "center";

    let reading = document.createTextNode(toolTipData?.soc + "%");

    readingElement.appendChild(reading);
    th.appendChild(readingElement);
    // th.appendChild(readingUnitElement);

    trHead.appendChild(th);
    tableHead.appendChild(trHead);

    const tableBody = document.createElement("tbody");

    const readingDateEl = document.createElement("span");

    readingDateEl.className = "reading-date-text";

    const trBody = document.createElement("tr");
    trBody.style.backgroundColor = "inherit";
    trBody.style.borderWidth = "0px";

    const td = document.createElement("td");
    td.style.borderWidth = "0px";



    const readingDate = moment(toolTipData.date_time_gmt_05).format(
        "D MMM, YYYY, h:mm A"
    );

    // console.log(readingDate);
    readingDateEl.textContent = readingDate;



    td.appendChild(readingDateEl);


    // td.appendChild(readingTime);
    trBody.appendChild(td);
    tableBody.appendChild(trBody);
    // });

    const tableRoot = tooltipEl.querySelector("table");

    // Remove old children
    while (tableRoot.firstChild) {
        tableRoot.firstChild.remove();
    }

    // Add new children
    tableRoot.appendChild(tableHead);
    tableRoot.appendChild(tableBody);
    //   }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
    //   console.log(positionY + tooltip.caretY - 62);
    // console.log("T", chart);
    // console.log("K", positionX);
    // console.log(tooltip.caretX);

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = `${positionX + tooltip.caretX - 7}px`;
    tooltipEl.style.top = `${tooltip.y - 24}px`;
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding = "5px";
};

export const externalTooltipHandlerBarChart = (context: { chart: any; tooltip: any; }, timeFilterValue: string) => {
    // Tooltip Element
    const { chart, tooltip } = context;
    if (!tooltip?.dataPoints?.length) return;
    if (timeFilterValue !== TimeFilterValue[1]) {
        if (tooltip.dataPoints[0].datasetIndex === 0) return;
    }

    const toolTipData = tooltip?.dataPoints[0]?.raw;
    const tooltipEl = getOrCreateTooltip(chart, "bar-chart");

    // console.log(tooltip);
    // console.log(tooltipEl);
    // console.log(toolTipData);
    // console.log(chart);

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
    }

    // Set Text
    //   if (tooltip.body) {

    const tableHead = document.createElement("thead");

    const trHead = document.createElement("tr");

    trHead.style.borderWidth = "0px";

    const readingElement = document.createElement("span");
    const B = document.createElement("b")
    B.textContent = "Energy Depleted: "
    readingElement.appendChild(B);
    readingElement.style.color = "grey";

    const th = document.createElement("th");
    th.style.borderWidth = "0px";
    th.style.display = "flex";
    th.style.alignItems = "center";

    let reading = document.createTextNode(toolTipData?.energy_consumption?.toFixed(2) + " kWh");

    readingElement.appendChild(reading);
    th.appendChild(readingElement);
    // th.appendChild(readingUnitElement);

    trHead.appendChild(th);
    tableHead.appendChild(trHead);

    const tableBody = document.createElement("tbody");

    const readingDateEl = document.createElement("span");

    readingDateEl.className = "reading-date-text";

    const trBody = document.createElement("tr");
    trBody.style.backgroundColor = "inherit";
    trBody.style.borderWidth = "0px";

    const td = document.createElement("td");
    td.style.borderWidth = "0px";



    const readingDate = moment(toolTipData.date).format(
        "D MMM, YYYY, h:mm A"
    );

    // console.log(readingDate);
    readingDateEl.textContent = readingDate;



    td.appendChild(readingDateEl);


    // td.appendChild(readingTime);
    trBody.appendChild(td);
    tableBody.appendChild(trBody);
    // });

    const tableRoot = tooltipEl.querySelector("table");

    // Remove old children
    while (tableRoot.firstChild) {
        tableRoot.firstChild.remove();
    }

    // Add new children
    tableRoot.appendChild(tableHead);
    tableRoot.appendChild(tableBody);
    //   }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
    //   console.log(positionY + tooltip.caretY - 62);
    // console.log("T", chart);
    // console.log("K", positionX);
    // console.log(tooltip.caretX);

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = `${positionX + tooltip.caretX - 5}px`;
    tooltipEl.style.top = `${tooltip.y - 15}px`;

    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding = "5px";
};

