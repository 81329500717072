import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pusher: {},
  address: "",
};

export const pusherSlice = createSlice({
  name: "pusher",
  initialState,
  reducers: {
    setData: (state, payloadData) => {
      const { payload } = payloadData;
      console.log("redux setData:", state);
      console.log("redux setData:", state.pusher);
      console.log("redux payload:", payload);

      //   return {
      //     ...state,
      //     pusher: { ...state.pusher, ...payload.pusher },
      //     address: payload.address ? payload.address : state.address,
      //   };
      state.pusher = { ...state.pusher, ...payload.pusher };
      state.address = payload.address ? payload.address : state.address;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setData } = pusherSlice.actions;

export default pusherSlice.reducer;
