import React from "react";
import { TimeFilterValue } from "../../Entities/Interfaces/IGraphs";
import { isVlektra } from "../../helper";

import "./scss/index.scss";
import { IProps } from "./types";

const DateFilterButtons: React.FC<IProps> = ({
  onFilterChange,
  timeFilterValue,
  disabled,
  timeFilterButtons,
  vtype
}: // destructure props here if needed
  IProps) => {
  return (
    <div className="filter-button">
      {timeFilterButtons.map((item, index: any) => (
        <button
          className={TimeFilterValue[timeFilterValue] === index ? "active" : ""}
          key={index.toString()}
          onClick={() => onFilterChange(index)}
          disabled={disabled}
          id={isVlektra(vtype) ? "isVlektra" : ""}
        >
          {item}
        </button>
      ))}
    </div>
  );
};

export default DateFilterButtons;
