function Crash() {
  let crashed;

  return (
    <div>
      <h1>{crashed.length ? "Crash" : "Crashed"}</h1>
    </div>
  );
}
export default Crash;
