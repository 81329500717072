import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Icons } from "../assets/icons";
import { isVlektra, getCurrentDate } from "../helper";
import axios from "axios";
// import { usePusher } from "../helper/Pusher/usePusher";

function JobList() {
  const queryParams = new URLSearchParams(window.location.search);
  // const pusher = queryParams.get("pusher");
  const cid = queryParams.get("cid");
  const vtype = queryParams.get("vtype");
  const isFleet = queryParams.get("fleet") === "true" ? true : false;

  const token = queryParams.get("token");

  const vId = parseInt(queryParams.get("vehicle_id"));

  const [jobList, setJobList] = useState([]);
  const [loader, setLoader] = useState(false);

  let navigate = useNavigate();

  useEffect(() => {
    fetchData();
    setInterval(() => {
      fetchData();
    }, 15000);
  }, []);

  // setInterval(() => {
  //   fetchData();
  // }, 15000);

  function fetchData() {
    const headers = {
      Authorization: `Bearer ${token}`,
      Domain: "Fleet",
    };
    const cDate = getCurrentDate();
    const url = `${process.env.REACT_APP_SERVER}/api/v1/routes/vehicle-jobs?vehicle_id=${vId}&date=${cDate}`;
    setLoader(true);
    axios
      .get(url, { headers })
      .then((response) => {
        // console.log("data of Jobs :::");
        setJobList(response.data.body.flat());
      })
      .finally(() => setLoader(false));
  }
  function goToDetail(row) {
    // if (sendEventToAndroid("eventBatteryManagementScreen")) {
    //   window.Android.eventBatteryManagementScreen(
    //     String(
    //       `https://${window.location.host}/BatteryManagement?vtype=${vtype}&fleet=${isFleet}&cid=${cid}`
    //     ),
    //     String("Battery Management")
    //   );
    // } else if (sendEventToiOS("eventBatteryManagementScreen")) {
    //   window.webkit.messageHandlers.eventBatteryManagementScreen.postMessage(
    //     String(
    //       `https://${window.location.host}/BatteryManagement?vtype=${vtype}&fleet=${isFleet}&cid=${cid}`
    //     ),
    //     String("Battery Management")
    //   );
    // }
    return navigate(
      `/jobDetail?vtype=${vtype}&fleet=${isFleet}&cid=${cid}&job_id=${row.id}&token=${token}`
    );
  }
  function goToRoute(event, row) {
    event.stopPropagation();
    return navigate(
      `/JobRoute?vtype=${vtype}&fleet=${isFleet}&cid=${cid}&job_id=${row.id}&token=${token}`
    );
  }
  const JobActivityTag = ({ item }) => {
    return (
      <div
        className="jobs d-flex align-center"
        onClick={() => goToDetail(item)}
      >
        {item.job_type !== "pick" && (
          <div>
            <img src={Icons.rightArrow} width={"25px"} alt="x" />
          </div>
        )}
        <div className={`job-activity-tag job-${item.job_type}`}>
          <div className="d-flex justify-space-between">
            <div className="info">
              <span>{item?.job_type}:</span>
              <span>{item?.client_name}</span>
            </div>
            <div className="status">
              {item.status === "progress" && (
                <div className="badge badge-primary"></div>
              )}
              {item.status === "pending" && (
                <div className="badge badge-warning"></div>
              )}
              {item.status === "completed" && (
                <div>
                  <img
                    src={Icons["double_checked"]}
                    width={"20px"}
                    alt="missing graphics"
                  ></img>
                </div>
                // <div className="badge badge-danger"></div>
              )}
            </div>
          </div>
          <div className="sub-info d-flex align-center justify-space-between">
            <div>{item.location_name}</div>
            <div onClick={(e) => goToRoute(e, item)}>
              <img src={Icons.address} width={"20px"} alt="x" />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="container_wrapper">
      {loader && (
        <div className="overlay">
          <div className="overlay__inner">
            <img
              src={
                isFleet
                  ? Icons.orko_loader
                  : isVlektra(vtype)
                  ? Icons.loader
                  : vtype === "sazgar-sazgar"
                  ? Icons.sazgar_loader
                  : Icons.ecodost_loader
              }
              className="overlay__content"
              width={"200px"}
              alt="missing graphics"
            ></img>
          </div>
        </div>
      )}
      <div className="screen-layout">
        {/* <div className="navbar">
          <div className="d-flex justify-center align-center">
            Pick and Drop
          </div>
        </div> */}
        <div className="jobActivity">
          {jobList.map((r, i) => (
            <JobActivityTag item={r} key={i} />
          ))}
        </div>
      </div>
    </div>
  );
}
export default JobList;
