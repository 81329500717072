import { useSelector } from "react-redux";
import { Icons } from "../assets/icons";
import { isVlektra } from "../helper";
// import { usePusher } from "../helper/Pusher/usePusher";

function Emission() {
  const queryParams = new URLSearchParams(window.location.search);
  // const pusher = queryParams.get("pusher");
  const vtype = queryParams.get("vtype");
  const isFleet = queryParams.get("fleet") === "true" ? true : false;

  const state = useSelector((state) => state.pusherSlice);
  const { pusher: data } = state;

  // const { data } = usePusher();

  // useEffect(() => {
  //   localStorage.setItem("pusher", JSON.stringify(data))
  // }, [data])

  return (
    <div className="container_wrapper">
      {data?.cumulative_emission_saving === "" && (
        <div className="overlay">
          <div className="overlay__inner">
            <img
              src={
                isFleet
                  ? Icons.orko_loader
                  : isVlektra(vtype)
                  ? Icons.loader
                  : vtype === "sazgar-sazgar"
                  ? Icons.sazgar_loader
                  : Icons.ecodost_loader
              }
              className="overlay__content"
              width={"200px"}
              alt="missing graphics"
            ></img>
          </div>
        </div>
      )}

      <center>
        <div className="tree_wrapper">
          <div>Congratulations!</div>
          <div>You have saved</div>
          <div>
            <img src={Icons.co2} width={"100px"} alt={"car-logo"} />
          </div>
          <div>{data?.cumulative_emission_saving} Kgs</div>
          <div>Carbon emissions</div>
          <div>which is equivalent to</div>
          <div>
            <img src={Icons.tree} width={"100px"} alt={"car-logo"} />
          </div>
          <div>{data?.tree_saved}</div>
          <div>{data?.tree_saved === "1" ? "Tree" : "Trees"} planted</div>
          <div>Thank you for making a positive impact!</div>
        </div>
      </center>
    </div>
  );
}
export default Emission;
